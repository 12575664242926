import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  }

  render() {
    const {
      onClick,
      props: {
        activeTab,
        label,
      },
    } = this;

    let className = 'tab-list-item';

    if (activeTab === label) {
      className += ' tab-list-active';
    }

    return (
      <li
        className={className}
        onClick={onClick}
      >
        {label}
      </li>
    );
  }
}

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props);

    console.log(props)
    this.state = {
      activeTab: props.children[0].props.label,
    };
  }
  
  handleChildTabChange = () => {
    this.props.onTabChange()
  }
  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
    this.handleChildTabChange()
  }

  render() {
    const {
      onClickTabItem,
      props: {
        children, mapClick
      },
      state: {
        activeTab,
      }
    } = this;

    return (
      <div className="tabs">
        <ol className="tab-list">
          {children.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={mapClick ? mapClick : activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="tab-content">
        {mapClick ? 
          children.map((child) => {
            if (child.props.label !== mapClick) return null;
            return child.props.children;
          }) : children.map((child) => {
            if (child.props.label !== activeTab) return null;
            return child.props.children;
          })
        }
          
        </div>
      </div>
    );
  }
}

export default Tabs;
