import React, { useCallback} from 'react';
import { Admin, CustomRoutes, Resource, usePermissions } from 'react-admin';
import dataProvider from './api/DataProvider';
import authProvider from './api/AuthProvider';
import { ClientList, ScreenShow, FormShow, DashboardShow, MenuShow, ActionShow, ClientEdit, TripSnapshotFormView, ClientCreate, ClientShow, ErrorShow, BackendUserShow, BackendUserEdit, EmailVerificationCreate, ErrorReportView } from './resources/clients';
import { BillingAccountList, BillingAccountShow, BillingAccountCreate, CustomAccountContractCreate } from './resources/billing_accounts';
import { PerLoadBillingCreate, PerLoadBillingEdit } from './resources/per_load_billing_structure';
import { CustomerList, CustomerShow, CustomerEdit } from './resources/customers';
import { DriveAxleList, DriveAxleShow } from './resources/drive_axle_users'
import { DocumentsList, DocumentsShow } from './resources/documents_fetch';
import { AppCreate, AppList, AppEdit, AppShow } from './resources/apps';
import { ClientGroupList, ClientGroupEdit, ClientGroupCreate, ClientGroupShow } from './resources/clientGroups';
import { PluggableRoutesFormView } from './resources/pluggableRoutes';
import { BackendUserMigrationDraftsCreate } from './resources/backendUserMigrationDrafts'
import { CustomerUnsubscribeDraftsCreate } from './resources/customerUnsubscribeDrafts'
import BusinessIcon from '@mui/icons-material/Business';
import './App.css';
import { renderIfPermissions } from './api/permissionManager';
import { CustomAppLayout, currentTheme } from './components/CustomAppBar';
import { ThemeProvider, createTheme } from '@mui/material';
import { StyledEngineProvider } from '@mui/material';
import { Route } from 'react-router-dom';
import mockAuthProvider from './api/MockAuthProvider';
import StyledReactGoogleLogin from './components/GoogleLogin';
import { CustomerAxleKeyRotationDraftsCreate } from './resources/customerAxleKeyRotationDrafts';
import { PermissionsList } from './resources/permissions_management';
import { GPXFormView } from './resources/gpxFiles';
import { BulkTriggerForm } from './components/BulkTrigger';
import { BillingProductCreate, BillingProductList, BillingProductShow, BillingPriceSheetList, BillingPriceSheetShow, PriceSheetItemCreate, BillingPriceSheetCreate, BillingContractStructureList, BillingContractStructureShow, ContractStructureProductCreate, BillingContractStructureCreate } from './resources/billing_resources';
import { ChargeShow, StripeChargeShow } from './resources/charges';
import { LandingPage } from './components/LandingPage';

const theme = createTheme();

function App() {
  const authedDataProvider = useCallback(
    (type, resource, params) => {
      const token = localStorage.getItem("token");
      return dataProvider(type, resource, params, `Bearer ${token}`);
    },
    []
  );
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
              <Admin title="Eleos Platform Admin"
                dataProvider={authedDataProvider}
                authProvider={process.env.REACT_APP_TESTING === 'true' ? mockAuthProvider : authProvider}
                loginPage={StyledReactGoogleLogin}
                layout={CustomAppLayout}
                theme={currentTheme}
                disableTelemetry
                >
                   {permissions => [
                <CustomRoutes>
                  <Route exact path="/" element={<LandingPage />} />
                </CustomRoutes>,
                <Resource name="clients"
                  list={renderIfPermissions(permissions, "clients", "read_only", ClientList)}
                  show={renderIfPermissions(permissions, "clients", "read_only", ClientShow)}
                  edit={renderIfPermissions(permissions, "clients", "write", ClientEdit)}
                  create={renderIfPermissions(permissions, "clients", "write", ClientCreate)}
                  icon={BusinessIcon}/>,
                <Resource name="billing_accounts"
                  key="name"
                  list={renderIfPermissions(permissions, "billing", "read_only", BillingAccountList)}
                  show={renderIfPermissions(permissions, "billing", "read_only", BillingAccountShow)}
                  create={renderIfPermissions(permissions, "billing", "write", BillingAccountCreate)}
                  icon={BusinessIcon}/>,
                <Resource 
                  name="reseller_billing_account" 
                  create={renderIfPermissions(permissions, "billing", "write", CustomAccountContractCreate)} 
                />,
                <Resource name="price_sheets"
                  list={renderIfPermissions(permissions, "billing", "read_only", BillingPriceSheetList)}
                  show={renderIfPermissions(permissions, "billing", "read_only", BillingPriceSheetShow)}
                  create={renderIfPermissions(permissions, "billing","write", BillingPriceSheetCreate)}
                />,
                <Resource name="price_sheet_items"
                  create={renderIfPermissions(permissions, "billing", "write", PriceSheetItemCreate)}
                />,
                <Resource name="products"
                  list={renderIfPermissions(permissions, "billing", "read_only", BillingProductList)}
                  show={renderIfPermissions(permissions,"billing", "read_only", BillingProductShow)}
                  create={renderIfPermissions(permissions,"billing", "write", BillingProductCreate)}
                />,
                <Resource name="contract_structures"
                  list={renderIfPermissions(permissions,"billing", "read_only", BillingContractStructureList)}
                  show={renderIfPermissions(permissions,"billing", "read_only", BillingContractStructureShow)}
                  create={renderIfPermissions(permissions,"billing", "write", BillingContractStructureCreate)}
                />,
                <Resource name="contract_structure_products"
                  create={renderIfPermissions(permissions,"billing", "write", ContractStructureProductCreate)}
                />,
                <Resource name="per_load_billing_structure"
                  create={renderIfPermissions(permissions,"billing", "write", PerLoadBillingCreate)}
                  edit={renderIfPermissions(permissions,"billing", "write", PerLoadBillingEdit)}/>,
                <Resource name="apps"
                  list={renderIfPermissions(permissions, "apps", "read_only", AppList)}
                  show={renderIfPermissions(permissions, "apps", "read_only", AppShow)}
                  create={renderIfPermissions(permissions,"apps", "write", AppCreate)}
                  edit={renderIfPermissions(permissions, "clients", "write", AppEdit)}/>,
                <Resource name="client_groups"
                  list={renderIfPermissions(permissions, "client_groups", "read_only", ClientGroupList)}
                  show={renderIfPermissions(permissions, "client_groups", "read_only", ClientGroupShow)}
                  create={renderIfPermissions(permissions, "client_groups", "write", ClientGroupCreate)}
                  edit={renderIfPermissions(permissions, "client_groups", "write", ClientGroupEdit)} />,
                <Resource name="trip_snapshots"
                  list={renderIfPermissions(permissions, "client_trip_plans", "read_only", TripSnapshotFormView)} />,
                <Resource name="error_reports"
                  list={renderIfPermissions(permissions, "clients", "write", ErrorReportView)} />,
                <Resource name="customers"
                  list={renderIfPermissions(permissions, "customers", "read_only", CustomerList)}
                  show={renderIfPermissions(permissions, "customers", "read_only", CustomerShow)}
                  edit={renderIfPermissions(permissions, "documents", "write", CustomerEdit)} />,
                <Resource name="pluggable_routes" 
                  list={renderIfPermissions(permissions, "routing", "write", PluggableRoutesFormView)} />,
                <Resource name="gpx_files"
                  list={renderIfPermissions(permissions, "routing", "write", GPXFormView)} />,
                <CustomRoutes>
                  <Route exact path="/charges/:customer_uuid/:charge_uuid" element={renderIfPermissions(permissions, "customers", "write", <ChargeShow/>)}/>
                </CustomRoutes>,
                <CustomRoutes>
                  <Route exact path="/stripe_charges/:stripe_charge_id" element={renderIfPermissions(permissions, "customers", "write", <StripeChargeShow />)}/>
                </CustomRoutes>,
                <Resource name="scanning_users"
                  list={renderIfPermissions(permissions, "customers", "read_only", DriveAxleList)}
                  show={renderIfPermissions(permissions, "customers", "read_only", DriveAxleShow)} />,
                <Resource name="documents"
                  list={renderIfPermissions(permissions, "documents", "read_only", DocumentsList)}
                  show={renderIfPermissions(permissions, "documents", "read_only", DocumentsShow)}/>,
                <Resource name="internal_permissions"
                    list={renderIfPermissions(permissions, "permissions", "read_only", PermissionsList)}/>,
                <Resource name="forms"/>,
                <CustomRoutes>
                  <Route exact path="/forms/:key/:uuid" element={<FormShow/>}/>
                </CustomRoutes>,
                <Resource name="screens"/>,
                <CustomRoutes>
                  <Route exact path="/screens/:key/:uuid" element={<ScreenShow/>}/>
                </CustomRoutes>,
                <Resource name="dashboards"/>,
                <CustomRoutes>
                  <Route exact path="/dashboards/:key/:uuid" element={<DashboardShow/>}/>
                </CustomRoutes>,
                <Resource name="actions"/>,
                <CustomRoutes>
                  <Route exact path="/actions/:key/:uuid" element={<ActionShow/>}/>
                </CustomRoutes>,
                <Resource name="menus"/>,
                <CustomRoutes>
                  <Route exact path="/menus/:key/:uuid" element={<MenuShow/>}/>
                </CustomRoutes>,
                <Resource name="errors"/>,
                <CustomRoutes>
                  <Route exact path="/errors/:key/:uuid" element={<ErrorShow/>}/>
                </CustomRoutes>,
                <Resource name="apple_accounts"/>,
                <Resource name="backend_users"
                  show={renderIfPermissions(permissions, "backend_users", "read_only", BackendUserShow)}
                  edit={renderIfPermissions(permissions,"backend_users", "write", BackendUserEdit)}
                />,
                <Resource name="changelog"/>,
                <Resource name="app_assets"/>,
                <Resource name="retention_policies"/>,
                <Resource name="backend_user_migration_drafts"
                  create={renderIfPermissions(permissions, "backend_users", "write", BackendUserMigrationDraftsCreate)} />,
                <Resource name="integration_metadata_formats" />,
                <Resource name="email_verifications"
                  create={renderIfPermissions(permissions,"clients", "write", EmailVerificationCreate)} />,
                <Resource name="customer_unsubscribe_drafts"
                  create={renderIfPermissions(permissions,"clients", "write", CustomerUnsubscribeDraftsCreate)} />,
                <Resource name="customer_key_rotation_drafts"
                  create={renderIfPermissions(permissions,"customers", "write", CustomerAxleKeyRotationDraftsCreate)} />,
                  <Resource name="bulk_triggers"
                  list={renderIfPermissions(permissions, "apps", "write", BulkTriggerForm)} />
               ]}
      </Admin>
      </ThemeProvider>
      </StyledEngineProvider>
    );
}

export default App;