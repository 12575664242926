import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Container } from '@mui/material';
import logo from '../resources/img/IA_Logo.png'
import { useAuthenticated, useLogout, useNotify } from 'react-admin';

export const LandingPage = () => {
    //Check for token in localStorage. Will logout if does not exist.
    useAuthenticated();
    const notify = useNotify();
    const logout = useLogout();
    
    const tokenCheck = async () => {
        const API_URL = (window.frameElement && window.frameElement.getAttribute("data-api-url")) || (process && process.env && process.env.REACT_APP_API_URL);
        const requestUrl = `${API_URL}/internal/administrators/me/permissions`;
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json' }
        };
        try {
            const response = await fetch(requestUrl, requestOptions);
            if (response.status === 403) logout();
            else if (!response.ok) notify('Error checking authentication', {type: 'error'});
        } catch (error) {
            notify('Error checking authentication', {type: 'error'});
        }
    };

    useEffect(() => {
        //Actually check validity of the token.
        tokenCheck();
    }, []);
    
    return (
        <div style={{ padding: '20px' }}>
            <Container>
                <Card style={{ textAlign: 'center' }}>
                    <CardContent>
                        <Typography variant="h4" style={{ marginTop: '1em', marginBottom: '1em' }} gutterBottom>
                            Welcome to Internal Admin
                        </Typography>
                        <div>
                            <img src={logo} height="80" alt="Logo" />
                        </div>
                        <Typography variant="body1" style={{ marginTop: '2em', marginBottom: '2em' }} paragraph>
                            You can access all of your available features and functionalities from the Menu on the left.
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
};
