import { useState } from "react";
import { Button, Datagrid, DateField, FunctionField, List, useListController, useRefresh } from "react-admin"
import Box from '@mui/material/Box';
import GetAppIcon from '@mui/icons-material/GetApp'
import { CircularProgress, Dialog, DialogActions, DialogContent, FormControl, IconButton, Input, InputLabel, Typography } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { decode } from './polyline-decoder';
import CopyBtn from '../resources/img/here-api-copy-btn.png'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const GPXFileList = (props) => {
    const { isLoading } = useListController();
    const [isUploadModalOpen, setUploadModalOpen] = useState(false);
    const refresh = useRefresh()
    if (isLoading) return <></>

    const getFileName = (url) => {
        const urlParts = url.split('/');
        const fileName = urlParts[urlParts.length - 1];
        return fileName;
    }

    const handleDownloadClick = async (url) => {
        try {
            const response = await fetch(url);
            if (response.ok) {
                // Extracting the filename from the URL
                const fileName = getFileName(url)

                const blob = await response.blob();

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();

                alert("GPX file downloaded successfully");
            } else {
                console.error("GPX file failed to download");
                alert("GPX file failed to download");
            }
        } catch (error) {
            console.error("Error during file download:", error);
        }
    };

    const handleUploadClick = () => {
        setUploadModalOpen(true);
    };

    const handleModalClose = () => {
        setUploadModalOpen(false);
    };

    const handleUploadSuccess = () => {
        refresh();
        setUploadModalOpen(false);
    };

    return (
        <div id="gpx_table">
            <h2 style={{ textAlign: "center", marginTop: "1em" }}><u>GPX Files</u></h2>
            <List pagination={false} exporter={false} title="GPX Files" style={{ marginTop: "-2em" }}>
                <div style={{ textAlign: "right" }}>
                    <Button style={{ fontSize: "large" }} onClick={handleUploadClick}>
                        Upload <CloudUpload style={{ marginLeft: "4px", fontSize: "x-large" }} />
                    </Button>
                </div>
                <Datagrid bulkActionButtons={false} size="medium">
                    <FunctionField source="url" label={"File Name"} render={record => `gpx/${getFileName(record.url)}`} sortable={false} />
                    <DateField source="last_modified" showTime sortable={false} />
                    <FunctionField sortable={false} label={"Download"} textAlign="center" render={record => <IconButton color="primary" onClick={() => handleDownloadClick(record.url)}><GetAppIcon /></IconButton>} />
                </Datagrid>
            </List>
            <Dialog open={isUploadModalOpen} onClose={handleModalClose} maxWidth="sm" fullWidth>
                <DialogContent>
                    <GPXUploadComponent onUploadSuccess={handleUploadSuccess} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const GPXUploadComponent = (props) => {
    const [file, setFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadMessage, setUploadMessage] = useState("");
    const token = localStorage.getItem("token");
    const API_URL = (window.frameElement && window.frameElement.getAttribute("data-api-url")) ||
        (process && process.env && process.env.REACT_APP_API_URL);

    const presignedURL = async (fileName) => {
        var requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };

        const response = await fetch(`${API_URL}/internal/demo_service_gpx_files/${fileName}`, requestOptions);
        if (response.ok) {
            var body = await response.json();
        }
        return body.url;
    }

    const handleFileUpload = async () => {
        if (!file) {
            setUploadMessage("Please choose a file before uploading.");
            return;
        }

        const data = await readFileContent(file);
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (fileExtension !== 'gpx') {
            setUploadMessage("Invalid file type! Please upload a GPX file.");
            return;
        }

        try {
            const url = await presignedURL(file.name);
            var requestOptions = {
                method: 'PUT',
                body: data
            };
            const response = await fetch(url, requestOptions);

            if (response.ok) {
                setUploadMessage(`GPX file uploaded successfully`);
                setFile(null);
                setUploadSuccess(true);
                props.onUploadSuccess();
            } else {
                const data = await response.json();
                var errorString = "\n";
                for (var error in data) {
                    const errorObj = data[error];
                    errorString = errorString + "> " + errorObj.description + "\n";
                }
                setUploadMessage(`GPX file failed to upload: ${errorString}`);
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const readFileContent = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onloadend = () => resolve(fileReader.result);
            fileReader.onerror = reject;
            fileReader.readAsArrayBuffer(file);
        });
    };

    const uploadButtonStyle = {
        display: 'flex',
        alignItems: 'center',
        margin: "0.3em 0",
        borderRadius: "2em",
        boxSizing: "border-box",
        fontWeight: "300",
        color: "#FFFFFF",
        backgroundColor: "#4eb5f1",
        cursor: "pointer",
    };

    return (
        <div id="FileHandler">
            <h3 style={{ textAlign: "center" }}><u>Upload GPX File</u></h3>
            <div id="FileHandler" style={{ textAlign: "center", display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <FormControl>
                    <Input id="upload_gpx" type="file" onChange={(e) => setFile(e.target.files[0])} />
                </FormControl>
                {file && !uploadSuccess && (
                    <button onClick={handleFileUpload} style={uploadButtonStyle}>
                        Upload <CloudUpload style={{ marginLeft: '4px' }} />
                    </button>
                )}
            </div>
            {uploadMessage && (
                <div style={{ textAlign: "center", marginTop: '4px', color: uploadSuccess ? "green" : "red" }}>
                    {uploadMessage}
                </div>
            )}
        </div>
    );
}

export const GenerateGPXComponent = (props) => {
    const [apiUrl, setApiUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState('');
    const [key, setKey] = useState('');

    const handleDownloadClick = async () => {
        try {
            setLoading(true);

            //Replace YOUR_API_KEY with the actual key
            if(key === "" && apiUrl.includes("YOUR_API_KEY")){
                alert("You must supply your api key")
                return;
            }
            const modifiedApiUrl = apiUrl.includes("YOUR_API_KEY")
            ? apiUrl.replace("YOUR_API_KEY", key)
            : apiUrl


            console.log("URL IS THIS: ",modifiedApiUrl)
            // Fetch data from the specified API URL
            const response = await fetch(modifiedApiUrl);
            const hereApiResponse = await response.json();

            // Assuming the structure is similar to the previous example
            const polyline = hereApiResponse.routes[0].sections[0].polyline;
            const decodedPolyline = decode(polyline);

            if (decodedPolyline && decodedPolyline.polyline) {
                const gpxContent = `<?xml version="1.0" encoding="UTF-8"?>
<gpx version="1.1" encoding="UTF-8" creator="${localStorage.user_email}">
  <trk>
    <trkseg>
      ${decodedPolyline.polyline.map(point => `\t<trkpt lat="${point[0]}" lon="${point[1]}"></trkpt>`).join('\n')}
    </trkseg>
  </trk>
</gpx>`;

                const blob = new Blob([gpxContent], { type: 'application/gpx+xml' });
                const url = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                if (fileName === '' || fileName === null) {
                    let date = new Date().toISOString()
                    a.download = `${date}.gpx`
                }
                else a.download = `${fileName}.gpx`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.error('Error generating and downloading GPX:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div id="gpx_table">
            <h3 style={{ textAlign: 'center', marginTop: '2em' }}><u>Generate GPX File</u></h3>
            <div style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '2em' }}>
                <h4><u>How to use:</u></h4>
                <li>Please go to the <a href="https://demo.routing.ext.here.com/#url=https://router.hereapi.com/v8/routes?apikey=DEFAULT_API_KEY&return=polyline,summary,actions,instructions&transportMode=truck" target="_blank">HERE Reference Client</a></li>
                <li>Build a route to your liking within the reference client.</li>
                <li>Copy your API key used in the Reference Client.</li>
                <li>Paste the key into the provided space on this page.</li>
                <li>Next to the API call, click the "Copy to Clipboard" button.
                    <ol><img src={CopyBtn} alt="arrow pointing to copy button" /></ol>
                </li>
                <li>Paste the copied API call into the provided space on this page.</li>
                <li>Specify a name of the GPX file you want to generate.</li>
                <ol>
                    <li>Please avoid using spaces.</li>
                    <li>If no name is specified, the filename will be the timestamp of the generated file.</li>
                </ol>
                <li>Click the "Download GPX" button to download the gpx file for the created route.</li>
            </div>

            <Box sx={{ width: '100%', p: 3 }}>
                <InputLabel style={{ marginTop: '1em' }}>Your API Key:</InputLabel>
                <Input
                    id="apiKeyInput"
                    variant="outlined"
                    value={key}
                    type="password"
                    onChange={(e) => setKey(e.target.value)}
                />
                <InputLabel htmlFor="apiUrlInput">API URL:</InputLabel>
                <Input
                    id="apiUrlInput"
                    fullWidth
                    variant="outlined"
                    value={apiUrl}
                    onChange={(e) => setApiUrl(e.target.value)}
                />
                <InputLabel style={{ marginTop: '1em' }} htmlFor="fileNameInput">File Name:</InputLabel>
                <Input
                    id="fileNameInput"
                    variant="outlined"
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                />
                <Button style={{ display: 'flex' }} variant="contained" onClick={handleDownloadClick} sx={{ mt: 2 }}>
                    {loading ? <CircularProgress size={24} /> : <>Download GPX<GetAppIcon style={{ marginLeft: '4px' }} /></>}
                </Button>
            </Box>
        </div>
    );
};

export const GPXFormView = (props) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="gpx tabs">
                    <Tab label="Available GPX List" {...a11yProps(0)} />
                    <Tab label="Generate GPX File" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel path="gpx_file_list" value={value} index={0}>
                <GPXFileList />
            </TabPanel>
            <TabPanel path="create_gpx_file" value={value} index={1}>
                <GenerateGPXComponent />
            </TabPanel>
        </Box>
    );
}