import React, { useState } from "react";
//import H from "@here/maps-api-for-javascript";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HERE_API_KEY = (window.frameElement && window.frameElement.getAttribute("here-map-api-key")) || (process && process.env && process.env.REACT_APP_HERE_MAP_API_KEY);

//Map marker icon
let svgMarkerSmall = '<svg width="24" height="24" viewBox="-4 0 36 36" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M14 0c7.732 0 14 5.641 14 12.6C28 23.963 14 36 14 36S0 24.064 0 12.6C0 5.641 6.268 0 14 0Z" fill="${COLOR1}"/><circle fill="${COLOR2}" fill-rule="nonzero" cx="14" cy="14" r="7"/></g></svg>'
let svgMarkerLarge = '<svg width="36" height="36" viewBox="-4 0 36 36" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M14 0c7.732 0 14 5.641 14 12.6C28 23.963 14 36 14 36S0 24.064 0 12.6C0 5.641 6.268 0 14 0Z" fill="${COLOR1}"/><circle fill="${COLOR2}" fill-rule="nonzero" cx="14" cy="14" r="7"/></g></svg>'

export const DifferencesMap = (props) => {
    const mapRef = React.useRef(null);
    const badChunks = props.badChunks;
    const routeFromClient = props.routeFromClient;
    const routeUpload = props.routeUpload;

    let [clickLocation, setClickLocation] = useState({});
    let [mapIndicators, setMapIndicators] = useState(false);

    //Create the map instance
    React.useLayoutEffect(() => {
        if (!mapRef.current) return;
        if (!HERE_API_KEY) {
            alert("ERROR: Could not fetch HERE maps api key")
            return;
        }

        const platform = new H.service.Platform({
            apiKey: HERE_API_KEY
        });

        let routes = new H.map.Group();
        let defaultLayers = platform.createDefaultLayers();

        let map;

        //swaps between map types
        if (mapIndicators) {
            map = new H.Map(mapRef.current, defaultLayers.vector.normal.truck, {
                center: { lat: 37.0902, lng: -95.7129 },
                zoom: 4,
                pixelRatio: window.devicePixelRatio || 1
            });
        }
        else {
            map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
                center: { lat: 37.0902, lng: -95.7129 },
                zoom: 4,
                pixelRatio: window.devicePixelRatio || 1
            });
        }

        window.addEventListener('resize', () => {
            try {
                map.getViewPort().resize()
            }
            catch {
                setTimeout(100)
            }
        });

        const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        const ui = H.ui.UI.createDefault(map, defaultLayers);


        setUpClickListener(map);

        if (routeFromClient && Object.keys(routeFromClient).length !== 0 && Object.keys(routeUpload).length == 0) {
            addRouteToMap(routeFromClient, "green", "blue", {
                lineWidth: 8,
                strokeColor: 'rgba(0, 0, 0, 0.9)',
                lineTailCap: 'arrow-tail',
                lineHeadCap: 'arrow-head'
            })
        }

        if (badChunks && Object.keys(badChunks).length !== 0 && Object.keys(routeUpload).length == 0) {
            badChunks.forEach((chunk, index) => {
                addBadChunksToMap(chunk, "red", "#FF8000", index + 1, {
                    lineWidth: 7,
                    fillColor: 'red',
                    strokeColor: 'rgba(255, 0, 0, .8)',
                    lineDash: [0, 1],
                    lineTailCap: 'arrow-tail',
                    lineHeadCap: 'arrow-head'
                });
            })
        }

        if (routeUpload && Object.keys(routeUpload).length !== 0) {
            addBadChunksToMap(routeUpload, "green", "blue", 1, {
                lineWidth: 8,
                fillColor: 'black',
                strokeColor: 'rgba(0, 0, 0, .9)',
                lineDash: [0, 0],
                lineTailCap: 'arrow-tail',
                lineHeadCap: 'arrow-head'
            })
        }

        if (routes.getObjects().length !== 0) {
            map.getViewModel().setLookAtData({
                bounds: routes.getBoundingBox()
            });
        }

        map.addObject(routes);

        function addMarkerToGroup(group, icon, coordinate, html) {
            let marker = new H.map.Marker(coordinate, { icon: icon });
            marker.setData(html);
            group.addObject(marker);
        }

        function addInfoBubble(map, icon, length, index, route) {
            let group = new H.map.Group();

            let startLat = route[0].lat;
            let startLng = route[0].lng;
            let stopLat = route[route.length -1].lat;
            let stopLng = route[route.length -1].lng;

            const copyChunk = JSON.stringify(route).replace(/"/g, '\\\"');

            map.addObject(group);

            group.addEventListener('tap', function (evt) {
                let bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                    content: evt.target.getData()
                });
                ui.addBubble(bubble);
            }, false);

            addMarkerToGroup(group, icon, { lat: startLat, lng: startLng },
                "<div style='white-space: nowrap; transform: scale(.8); padding: -1rem; margin: -1rem';>" +
                    `<p style='margin: -.1rem; font-weight: bold;'>Chunk ${index}</p>` +
                    `<a style='margin: -.1rem' href='https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${startLat},${startLng}&heading=0&pitch=0&fov=100' target="_blank" rel="noopener noreferrer">Street View</a>` +
                    `<p style='margin: -.1rem'>Length: ${length} miles</p>` +
                    `<p style='margin: -.1rem'>Start: ${startLat.toFixed(5)}, ${startLng.toFixed(5)}</p>` +
                    `<p style='margin: -.1rem'>Stop: ${stopLat.toFixed(5)}, ${stopLng.toFixed(5)}</p>` +
                    `<br />
                        <button onclick='(function(){
                            navigator.clipboard.writeText("${copyChunk}");
                            return false;
                        })();return false;'>Copy all coordinates</button>
                    <br />` +
                "</div>");
        }

        function calculateDistance(route) {
            let total = 0;
            for (let i = 0; i < route.length - 1; i++) {
                let p1 = new H.geo.Point(route[i].lat, route[i].lng);
                let p2 = new H.geo.Point(route[i + 1].lat, route[i + 1].lng);
                total += p1.distance(p2);
            }
            const metersToMiles = 1609.344;
            return (total / metersToMiles).toFixed(2);
        }

        function setUpClickListener(map) {
            map.addEventListener('tap', function (evt) {
                let coord = map.screenToGeo(evt.currentPointer.viewportX,
                    evt.currentPointer.viewportY);

                setClickLocation({
                    "lat": coord.lat.toFixed(5),
                    "lng": coord.lng.toFixed(5)
                })
            });
        }

        function addRouteToMap(route, startColor, endColor, style) {
            let linestring = new H.geo.LineString();

            route.forEach(({ latitude, longitude }) => {
                linestring.pushPoint({ lat: latitude, lng: longitude })
            });

            let startIcon = new H.map.Icon(svgMarkerLarge.replace('${COLOR1}', startColor));
            let startMarker = new H.map.Marker({ lat: route[0].latitude, lng: route[0].longitude }, { icon: startIcon });

            let endIcon = new H.map.Icon(svgMarkerLarge.replace('${COLOR1}', endColor));
            let lastPoint = route[route.length - 1];
            let endMarker = new H.map.Marker({ lat: lastPoint.latitude, lng: lastPoint.longitude }, { icon: endIcon });

            let polyline = new H.map.Polyline(linestring, {
                style: style
            });
            routes.addObjects([startMarker, endMarker, polyline]);
        }

        function addBadChunksToMap(route, startColor, endColor, index, style) {
            let linestring = new H.geo.LineString();

            let startIcon = new H.map.Icon(svgMarkerSmall.replace('${COLOR1}', startColor).replace('${COLOR2}', 'white'));

            let endIcon = new H.map.Icon(svgMarkerSmall.replace('${COLOR1}', endColor));
            let lastPoint = route[route.length - 1];
            let endMarker = new H.map.Marker({ lat: lastPoint.lat, lng: lastPoint.lng }, { icon: endIcon });

            route.forEach(({ lat, lng }) => {
                linestring.pushPoint({ lat: lat, lng: lng })
            });

            addInfoBubble(map, startIcon, calculateDistance(route), index, route);

            let polyline = new H.map.Polyline(linestring, {
                style: style
            });

            routes.addObjects([polyline, endMarker]);
        }

        return () => {
            map.dispose();
        };
    }, [mapRef, mapIndicators]);

    let handleMapInfo = () => {
        return (
            <div>
                {(badChunks && Object.keys(badChunks).length != 0 || Object.keys(routeUpload).length != 0) ? null : <div style={{ color: "red" }}> <p>No Pluggable Routing Errors Found</p> </div>}
                <Accordion style={{ width: "64vw", marginBottom: "1rem", marginTop: "1rem" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>Map Information:</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <div>
                                <p>{clickLocation.lat ? ("Mouse Click Location: " + clickLocation.lat + ", " + clickLocation.lng) : <p>Click on the map to determine a specific coordinate.</p>}</p>
                            </div>
                            <div>
                                <li>Pluggable route is shown with a black line from the <span style={{ color: "green" }}>GREEN</span> marker to the <span style={{ color: "blue" }}>BLUE</span> marker</li>
                                <li>Route errors are shown with a dotted red line from the <span style={{ color: "red" }}>RED</span> marker to the <span style={{ color: "#FF8000" }}>ORANGE</span> marker</li>
                                <li>Clicking on the <span style={{ color: "red" }}>RED</span> marker will provide information about that section {"("}start/stop coordinate, length, street view{")"}</li>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        )
    }

    return (
        <div>
            {handleMapInfo()}
            <div className="map" ref={mapRef} style={{ width: "70vw", height: "70vh" }} />
        </div>
    )
};
