// in src/Menu.js
import * as React from 'react';
import DirectionsIcon from '@mui/icons-material/Directions';
import { useMediaQuery, MenuItem } from '@mui/material';
import { MenuItemLink, FilterContext, Menu, useResourceDefinitions, useSidebarState, usePermissions } from 'react-admin';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { renderIfPermissions } from './api/permissionManager';
import PaidIcon from '@mui/icons-material/Paid';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  menuItemLink: {
    padding: "16px",
    paddingLeft: "34px",
  },
  menuItem: {
    backgroundColor: "#fff",
    paddingTop: "12px",
    paddingBottom: "12px",
    color: "inherit"
  },
  menuItemNoExpand: {
    backgroundColor: "#fff",
    paddingTop: "16px",
    paddingBottom: "16px",
    color: "inherit"
  },
  listIcon: {
    minWidth: "40px",
  },
})

const CustomMenu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [open] = useSidebarState();
  const [platExpand, setPlatExpand] = React.useState(true);
  const [axleExpand, setAxleExpand] = React.useState(true);
  const [billingExpand, setBillingExpand] = React.useState(true);
  const [routingExpand, setRoutingExpand] = React.useState(true);
  const [permissionsExpand, setPermissionsExpand] = React.useState(true);

  const { permissions } = usePermissions();

  const classes = useStyles()

  const handlePlatform = () => setPlatExpand(!platExpand)
  const handleBilling = () => setBillingExpand(!billingExpand)
  const handleDriveAxle = () => setAxleExpand(!axleExpand)
  const handleRouting = () => setRoutingExpand(!routingExpand)
  const handlePermissions = () => setPermissionsExpand(!permissionsExpand)

  const hasVisiblePlatformResources = () => {
    const platformResources = ['clients', 'client_groups', 'apps'];
    return platformResources.some(resource => renderIfPermissions(permissions, resource, "read_only", <div />) !== null);
  };
  const hasVisibleDriveAxleResources = () => {
    const platformResources = ['customers', 'documents'];
    return platformResources.some(resource => renderIfPermissions(permissions, resource, "read_only", <div />) !== null);
  };
  const hasVisibleRoutingResources = () => {
    const platformResources = ['routing', 'client_trip_plans'];
    return platformResources.some(resource => renderIfPermissions(permissions, resource, "read_only", <div />) !== null);
  };

  const renderMenuItems = () => {
    return (
      <Menu>
        <List sx={{ paddingTop: '0' }}>
          {hasVisiblePlatformResources() && (
            <MenuItem className={classes.menuItem} onClick={handlePlatform} divider={true}>
              <ListItemIcon className={classes.listIcon}><PhonelinkIcon /></ListItemIcon>
              <ListItemText primary="Platform" />
              {platExpand ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>)}
          <Collapse in={platExpand} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderIfPermissions(permissions, "clients", "read_only",
                <MenuItemLink
                  key={'clients'}
                  to={`/clients`}
                  primaryText={"Clients"}
                  onClick={onMenuClick}
                  className={classes.menuItemLink}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "client_groups", "read_only",
                <MenuItemLink
                  key={'client_groups'}
                  to={`/client_groups`}
                  primaryText={"Client Groups"}
                  onClick={onMenuClick}
                  className={classes.menuItemLink}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "apps", "read_only",
                <MenuItemLink
                  key={'apps'}
                  to={`/apps`}
                  primaryText={"Apps"}
                  onClick={onMenuClick}
                  className={classes.menuItemLink}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "apps", "write",
                <MenuItemLink
                  key={'bulk_triggers'}
                  to={'/bulk_triggers'}
                  primaryText={"Bulk Trigger"}
                  onClick={onMenuClick}
                  className={classes.menuItemLink}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "clients", "write",
                <MenuItemLink
                  key={'error_reports'}
                  to={`/error_reports`}
                  primaryText={"Error Reports"}
                  onClick={onMenuClick}
                  className={classes.menuItemLink}
                  sidebarIsOpen={open}
                />)}
            </List>
          </Collapse>

          {renderIfPermissions(permissions, "billing", "read_only",
            <>
              <MenuItem className={classes.menuItem} onClick={handleBilling} divider={true}>
                <ListItemIcon className={classes.listIcon}><PaidIcon /></ListItemIcon>
                <ListItemText primary="Billing" />
                {billingExpand ? <ExpandLess /> : <ExpandMore />}
              </MenuItem>
              <Collapse in={billingExpand} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <MenuItemLink
                    key={'billing_accounts'}
                    to={`/billing_accounts`}
                    primaryText={"Billing Accounts"}
                    onClick={onMenuClick}
                    className={classes.menuItemLink}
                    sidebarIsOpen={open}
                  />
                  <MenuItemLink
                    key={'contract_structures'}
                    to={`/contract_structures`}
                    primaryText={"Contract Structures"}
                    onClick={onMenuClick}
                    className={classes.menuItemLink}
                    sidebarIsOpen={open}
                  />
                  <MenuItemLink
                    key={'price_sheets'}
                    to={`/price_sheets`}
                    primaryText={"Price Sheets"}
                    onClick={onMenuClick}
                    className={classes.menuItemLink}
                    sidebarIsOpen={open}
                  />
                  <MenuItemLink
                    key={'products'}
                    to={`/products`}
                    primaryText={"Products"}
                    onClick={onMenuClick}
                    className={classes.menuItemLink}
                    sidebarIsOpen={open}
                  />
                </List>
              </Collapse>
            </>
          )}

          {hasVisibleDriveAxleResources() && (
            <MenuItem className={classes.menuItem} onClick={handleDriveAxle} divider={true}>
              <ListItemIcon className={classes.listIcon}><DescriptionOutlinedIcon /></ListItemIcon>
              <ListItemText primary="Drive Axle" />
              {axleExpand ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>)}
          <Collapse in={axleExpand} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderIfPermissions(permissions, "customers", "read_only",
                <MenuItemLink
                  key={'customers'}
                  to={`/customers`}
                  primaryText={"Hub Customers"}
                  onClick={onMenuClick}
                  className={classes.menuItemLink}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "customers", "read_only",
                <MenuItemLink
                  key={'scanning_users'}
                  to={`/scanning_users`}
                  primaryText={"Drive Axle Users"}
                  onClick={onMenuClick}
                  className={classes.menuItemLink}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "documents", "read_only",
                <MenuItemLink
                  key={'documents'}
                  to={'/documents'}
                  primaryText={"Get Documents"}
                  onClick={onMenuClick}
                  className={classes.menuItemLink}
                  sidebarIsOpen={open}
                />)}

            </List>
          </Collapse>

          {hasVisibleRoutingResources() && (
            <MenuItem className={classes.menuItem} button onClick={handleRouting} divider={true}>
              <ListItemIcon className={classes.listIcon}><DirectionsIcon /></ListItemIcon>
              <ListItemText primary="Routing" />
              {routingExpand ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>)}
          <Collapse in={routingExpand} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderIfPermissions(permissions, "client_trip_plans", "read_only",
                <MenuItemLink
                  key={'trip_snapshots'}
                  to={`/trip_snapshots`}
                  primaryText={"Trip Snapshots"}
                  onClick={onMenuClick}
                  className={classes.menuItemLink}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "routing", "write",
                <MenuItemLink
                  key={'pluggable_routes'}
                  to={`/pluggable_routes`}
                  primaryText={"Pluggable Routes"}
                  onClick={onMenuClick}
                  className={classes.menuItemLink}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "routing", "write",
                <MenuItemLink
                  key={'gpx_files'}
                  to={`/gpx_files`}
                  primaryText={"GPX Files"}
                  onClick={onMenuClick}
                  className={classes.menuItemLink}
                  sidebarIsOpen={open}
                />)}
            </List>
          </Collapse>
          {renderIfPermissions(permissions, "permissions", "write",
            <MenuItem className={classes.menuItem} button onClick={handlePermissions} divider={true}>
              <ListItemIcon className={classes.listIcon}><DescriptionOutlinedIcon /></ListItemIcon>
              <ListItemText primary="Permissions" />
              {permissionsExpand ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
          )}
          {renderIfPermissions(permissions, "permissions", "write",
            <Collapse in={permissionsExpand} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <MenuItemLink
                  key={'permissions'}
                  to={`/internal_permissions`}
                  primaryText={"Permissions Management"}
                  onClick={onMenuClick}
                  className={classes.menuItemLink}
                  sidebarIsOpen={open}
                />
              </List>
            </Collapse>
          )}
        </List>
      </Menu>
    );
  }

  if (open) {
    return (<div>
      {renderMenuItems()}
      {isXSmall && logout}
    </div>);
  }
  else {
    return (<div></div>)
  }
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter(CustomMenu);