import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from '@mui/material';
import { useGetList, useGetOne } from 'react-admin';

export const ErrorReportForm = () => {
    const [username, setUsername] = useState('');
    const [clientkey, setClientkey] = useState('');
    const [requestList, setRequestList] = useState(false);
    const [requestSingle, setRequestSingle] = useState(false);
    const [unsortedListData, setData] = useState([]);
    const [reportuuid, setReportuuid] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortedField, setSortedField] = useState('report_time');
    const [noReports, setNoReports] = useState(false);

    const { data, error, isLoading } = useGetList('issue_report', { id: clientkey, meta: { username, client_key: clientkey } }, { enabled: requestList });
    const { data: reportdata, error: reporterror, isLoading: reportisLoading } = useGetOne('issue_report', { id: reportuuid }, { enabled: requestSingle });

    useEffect(() => {
        if (requestList && !isLoading) {
            if (error) {
                if (error.status == 404){
                    setData([])
                    alert("Invalid Client Key or Username")
                }
                else{
                    console.log("request failed");
                }
            } else if (data && data.length > 0) {
                setData(data);
                setNoReports(false);
            } else {
                setData([]);
                setNoReports(true);
            }
            setRequestList(false);
        }
    }, [requestList, data, error, isLoading]);
    
    useEffect(() => {
        if (requestSingle && !reportisLoading) {
            if (reporterror) {
                console.log("Request failed", reporterror);
            } else if (reportdata) {
                window.open(reportdata.url, '_blank');
            }
            setRequestSingle(false);
        }
    }, [requestSingle, reportdata, reporterror, reportisLoading, reportuuid]);

    const submitHandler = (event) => {
        event.preventDefault();
        if (!clientkey || !username) {
            alert("Both Client Key and Username are required");
            return;
        }
        setRequestList(true);
    };

    const downloadReportbutton = (uuid) => {
        setReportuuid(uuid);
        setRequestSingle(true);
    };

    const changeSortOrder = (field) => {
        const isAsc = sortedField === field && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortedField(field);
    };

    const sortedListData = [...unsortedListData].sort((a, b) => {
        return sortDirection === 'asc'
            ? new Date(a[sortedField]) - new Date(b[sortedField])
            : new Date(b[sortedField]) - new Date(a[sortedField]);
    });

    return (
        <div>
            <h2>Error Report Tool</h2>
            <p>Here you can enter a client key and a Username, and receive a list of related error reports</p>
            <Container>
                <form onSubmit={submitHandler}>
                    <div style={{ margin: '8px' }}>
                        <TextField
                            id='clientKey-input'
                            variant="outlined"
                            label="Client Key"
                            name='clientKey'
                            size="small"
                            value={clientkey}
                            onChange={(e) => setClientkey(e.target.value)}
                        />
                    </div>
                    <div style={{ margin: '8px' }}>
                        <TextField
                            id='username-input'
                            variant="outlined"
                            label="Username"
                            name='username'
                            size="small"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div style={{ margin: '8px' }}>
                        <Button type='submit' variant="contained" color="primary" disabled={isLoading}>
                            {isLoading ? 'Loading...' : 'Send Request'}
                        </Button>
                    </div>
                </form>
            </Container>
            {noReports && <p>No error reports found for the provided client key and username.</p>}
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={sortedField === 'report_time'}
                                    direction={sortDirection}
                                    onClick={() => changeSortOrder('report_time')}
                                >
                                    Report Time
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>UUID</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedListData.map((reports) => (
                            <TableRow key={reports.uuid}>
                                <TableCell>{reports.report_time}</TableCell>
                                <TableCell>{reports.uuid}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" onClick={() => downloadReportbutton(reports.uuid)}>
                                        Open Report
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};