import React from "react";
import { Create, Edit, SimpleForm, List, Datagrid, TextField, TextInput,
    BooleanInput, Filter, Show, SaveButton,
    Toolbar, TabbedShowLayout, Tab, required,
    BooleanField, ReferenceField, ReferenceInput, AutocompleteInput, useRecordContext,
    usePermissions, ArrayField, DateField, ArrayInput, SimpleFormIterator
} from "react-admin";
import { validateJSON } from "../api/validators";
import { Grid, Box } from "@mui/material";
import AppCreateDoc from "../documentation/AppCreateDoc"
import JSONPretty from 'react-json-pretty';
import { renderIfPermissions } from "../api/permissionManager";
import { Changelog } from "../components/Changelog";
import KeySwitchCreateButton from "../components/KeySwitchCreate";

export const PrettyJsonField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    return (<JSONPretty id="json-pretty" data={record[source]} ></JSONPretty>);
  }

export const AppEditToolbar = () => (
    <Toolbar>
        <SaveButton/>
    </Toolbar>
  );

export const AppCreate = () => (
    <Grid container direction={'row'}>
        <Create>
            <SimpleForm mode="onBlur">
                <TextInput source="client_key" label="client key" validate={required()}/>
                <TextInput source="app_name" label="App name" validate={required()}/>
                <TextInput source="ios_bundle_name" label="iOS bundle name" validate={required()}/>
                <TextInput source="ios_here_id" label="iOS HERE id"/>
                <TextInput source="ios_here_code" label="iOS HERE code"/>
                <TextInput source="ios_here_key" label="iOS HERE key"/>
                <TextInput source="ios_environment_properties" label="iOS environment properties" multiline validate={validateJSON}/>
                <TextInput source="android_package_name" label="Android package name" validate={required()}/>
                <TextInput source="android_here_id" label="Android HERE id"/>
                <TextInput source="android_here_code" label="Android HERE code"/>
                <TextInput source="android_here_key" label="Android HERE key"/>
                <TextInput source="android_environment_properties" multiline label="Android Environment Properties" validate={validateJSON}/>
                <ArrayInput source="android_signing_fingerprints" label="Android Signing Fingerprints" validate={required()}>
                  <SimpleFormIterator inline disableReordering getItemLabel={index => `Fingerprint ${index+1}`}>
                    <TextInput source="." helperText={false} fullWidth />
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput source="firebase_project_id" label="Firebase project id" validate={required()}/>
                <ReferenceInput label="Apple Team Identifier" source="apple_team_identifier" reference="apple_accounts" validate={required()}>
                    <AutocompleteInput optionText="name" suggestionLimit={10}/>
                </ReferenceInput>
                <BooleanInput source="apns_production" label="APNS Production" defaultValue={false} validate={required()}/>
                <BooleanInput source="is_billable" label="Billable" defaultValue={false}/>
            </SimpleForm>
        </Create>
        <Box px={4} py={2}>
            <AppCreateDoc/>
        </Box>
    </Grid>
);

const transform = (data) => {
    let sanitizedData = data;
    for (const key in data) {
        if (typeof data[key] === "string" && data[key].trim().length === 0) {
            sanitizedData[key] = null; 
        }
        if (key === "android_signing_fingerprints" && sanitizedData[key] !== null && typeof sanitizedData[key] === "string") {
          let fingerprints = sanitizedData[key].replace(/ /g, "").split(',');
          sanitizedData[key] = fingerprints.map(fp => fp.trim());
        }

    }
    return sanitizedData;
};

export const AppEdit = () => {
  const { permissions } = usePermissions();

  return(
    <Edit transform={transform}>
        <SimpleForm toolbar={renderIfPermissions(permissions, "apps", "write", <AppEditToolbar/>)} mode="onBlur" redirect="show" destroyOnUnregister={true} sanitizeEmptyValues={true}>
            <TextInput source="client_key" label="client key" disabled/>
            <TextInput source="app_name" label="App name" validate={required()}/>
            <TextInput source="ios_bundle_name" label="iOS bundle name"/>
            <TextInput source="ios_here_id" label="iOS HERE id"/>
            <TextInput source="ios_here_code" label="iOS HERE code"/>
            <TextInput source="ios_here_key" label="iOS HERE key"/>
            <TextInput source="ios_environment_properties" label="iOS environment properties" multiline validate={validateJSON} />
            <TextInput source="android_package_name" label="Android package name"/>
            <TextInput source="android_here_id" label="Android HERE id"/>
            <TextInput source="android_here_code" label="Android HERE code"/>
            <TextInput source="android_here_key" label="Android HERE key"/>
            <TextInput source="android_environment_properties" label="Android environment properties" multiline validate={validateJSON} />
            <TextInput source="android_signing_fingerprints" label="Android Signing Fingerprints" />
            <TextInput source="firebase_project_id" label="Firebase project id"/>
            <ReferenceInput label="Apple Team Identifier" source="apple_team_identifier" reference="apple_accounts" validate={required()}>
                <AutocompleteInput optionText="name" suggestionLimit={10}/>
            </ReferenceInput>
            <BooleanInput source="apns_production" label="APNS Production" defaultValue={false}/>
            <BooleanInput source="is_billable" label="Billable"/>
        </SimpleForm>
    </Edit>
  );
};

export const AppFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search by key/name" source="app_name" alwaysOn />
  </Filter>
);

export const AppList = (props) => (
    <List pagination={false} filters={<AppFilter/>} exporter={false}>
        <Datagrid key={props.id} rowClick="show"  bulkActionButtons={false} size="medium">
            <TextField source="client_key" label="Client Key" sortable={false}/>
            <TextField source="app_name" label="Name" sortable={false}/>
            <TextField source="ios_bundle_name" label="iOS Bundle" sortable={false}/>
            <TextField source="android_package_name" label="Android Bundle" sortable={false}/>
        </Datagrid>
    </List>
);

export const AppShow = () => {
  const { permissions } = usePermissions();
  return(
    <Show>
        <TabbedShowLayout spacing={2}>
          <Tab label="app information">
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Tab label="info">
                  {renderIfPermissions(permissions, "clients", "read_only",
                    <ReferenceField label="Client Key" source="client_key" reference="clients" link="show">
                      <TextField source="key"/>
                    </ReferenceField>)}
                  <TextField source="app_name" label="Name"/>
                  <TextField source="ios_bundle_name" label="iOS Bundle"/>
                  <TextField label="iOS Environment Properties" />
                  <PrettyJsonField source="ios_environment_properties"/>
                  <TextField source="android_package_name" label="Android Bundle"/>
                  <TextField label="Android Environment Properties" />
                  <PrettyJsonField source="android_environment_properties"/>
                  <BooleanField source="apns_production" label="APNS Production"/>
                  <BooleanField source="is_billable" label="Is billable"/>
                  <TextField source="apple_team_identifier" label="Apple Team Identifier"/>
                  <TextField source="android_signing_fingerprints" label="Android Signing Fingerprints" />
                </Tab>
              </Grid>
              {renderIfPermissions(permissions, "clients", "write",
                  <Grid item xs={5}>
                    <Changelog from="apps" />
                  </Grid>
              )}
            </Grid>
          </Tab>
          <Tab label="HERE key permission">
            <TextField label="iOS HERE Permissions"/>
            <PrettyJsonField source="ios_here_permissions" />
            <TextField label="Android HERE Permissions"/>
            <PrettyJsonField source="android_here_permissions" />
          </Tab>
            <Tab label="Key Switchers">
                {renderIfPermissions(permissions, "apps", "write", <KeySwitchCreateButton/>)}
                <ArrayField source="pledges">
                    <Datagrid>
                        {renderIfPermissions(permissions, "clients", "read_only",
                          <ReferenceField reference="clients" source="client_key" link="show">
                              <TextField source="key"/>
                          </ReferenceField>)}
                        <DateField showTime label="Last used" source="last_used_at"/>
                        <TextField label="Key switcher" source="url" />
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
  );
};
