import React, { useState } from "react";
import uuid from 'react-uuid';
import {
  Create, Edit, SimpleForm, List, Datagrid, TextField, TextInput,
  ShowButton, SelectInput, BooleanInput, Toolbar, SaveButton,
  NumberInput, TopToolbar, FormTab, TabbedForm, required, ReferenceManyField,
  BooleanField, ReferenceInput, AutocompleteInput, Show, DateField,
  TabbedShowLayout, Tab, ArrayInput, SimpleFormIterator, ArrayField, regex, useGetOne,
  Loading, Error, NullableBooleanInput, ReferenceField, usePermissions, useRecordContext, Link, EditButton, Filter, Labeled, ImageField,
  useNotify, useRefresh, useRedirect, useShowController, useShowContext, TabbedShowLayoutTabs, WrapperField, WithRecord, useListController, useGetManyReference,
  minValue, useCreatePath, useUpdate, NotFound, Confirm, useResourceContext
} from "react-admin";
import { renderIfPermissions } from "../api/permissionManager";

import MessageIcon from '@mui/icons-material/Message'
import { Alert, Button as MaterialButton, Tabs as MaterialTabs, Tab as MaterialTab, Divider } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { TriggerBuildForm } from "../components/AppButtons";
import { TripSnapshotForm } from "../components/TripSnapshotForm"
import { ErrorReportForm } from "../components/ErrorReportForm"
import { UserSearchForm } from "../components/UserSearchForm"
import { DeactivateClientButton, ActivateBillableClientButton, ActivateNonBillableClientButton } from "../components/ActiveToggleButtons"
import JSONPretty from 'react-json-pretty';
import { Grid, Box, Typography, InputLabel, Card } from "@mui/material";
import ClientCreateDoc from "../documentation/ClientCreateDoc"
import ClientEditDoc from "../documentation/ClientEditDoc"
import { FileUploadComponent } from '../components/FileUpload';
import { AppAssetUploadComponent } from "../components/AppAssetUpload";
import { AltDashboardUploadComponent } from "../components/AltDashboardUpload";
import DownloadLink from 'react-download-link';
import { RoleDeleteButton, AddRoleButton } from "../components/RoleEditButtons";
import { BlockDeleteButton, BounceDeleteButton } from "../components/SendgridDeleteButtons";
import { useParams, Link as RouterLink, useSearchParams } from 'react-router-dom';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Tabs from "../components/Tabs"
import { RouteImportForm } from "../components/RouteImportForm"
import { ResendInviteButton } from "../components/ResendInvite";
import get from 'lodash/get';
import { ActiveButton } from "../components/ActiveButton";
import { Changelog } from "../components/Changelog";
import './clients.css';
import { BackendUserMigrationDraftsField, MigrateButton } from "./backendUserMigrationDrafts";
import dataProvider from "../api/DataProvider";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import SendIcon from '@mui/icons-material/Send';
import { TripPlannerMap } from "../components/TripPlannerMap";
import { BackendUserEmailVerificationsField } from "./backendUserEmailVerifications";
import { ShowDocTypes } from "../components/DocTypes";

const DownloadField = (props) => {
  const record = useRecordContext(props);
  const resource = useResourceContext();
  return (
    <DownloadLink
      label="Download"
      filename={record.code + ".json"}
      exportFile={() =>
        resource == "screens"
          ? JSON.stringify(
            Object.assign(
              { ...record },
              { definition: JSON.stringify(record.definition) }
            )
          )
          : JSON.stringify(record)
      }
    />
  );
};

const PrettyJsonField = ({ source, ...props }) => {
  const record = useRecordContext(props);
  if (record[source] === undefined) return
  return (<JSONPretty id="json-pretty" data={record[source]}></JSONPretty>);
}

const ClientFilter = (props) => {
  return (<Filter {...props}>
    <TextInput label="Search by Client Name" source="name" alwaysOn />
    <NullableBooleanInput label="Active" source="active" alwaysOn />
    <SelectInput source="role" label="Client Type"
      choices={[
        { id: 'live', name: 'Live' },
        { id: 'sandbox', name: 'Sandbox' },
        { id: 'guest', name: 'Guest' }
      ]}
    />
    <TextInput label="Live Client Key" source="live_client_key" />
    <BooleanInput label="Billable" source="is_usage_billable" defaultValue={true} />
    <ReferenceInput label="Client Group" source="client_group_key" reference="client_groups" >
      <AutocompleteInput optionText="name" suggestionLimit={10} />
    </ReferenceInput>
  </Filter>
  )
};

export const TripPlannerField = (props) => {
  const record = useRecordContext(props);
  if (!record) return null;
  if (record.trip_planner === "enabled") return <CheckBoxIcon fontSize="small" />
  if (record.trip_planner === "optional") return <IndeterminateCheckBoxIcon fontSize="small" />
  if (record.trip_planner === "disabled") return <CheckBoxOutlineBlankIcon fontSize="small" />
  return record ? <span>{record.trip_planner}</span> : null;
}

export const ClientList = (props) => {
  const { isLoading } = useListController();
  if (isLoading) return <></>

  return (
    <List pagination={false} filters={<ClientFilter />} filterDefaultValues={{ active: true }} exporter={false} >
      <Datagrid rowClick="show" bulkActionButtons={false} size="medium">
        <TextField source="name" sortable={false} />
        <WithRecord label="Client Key" render={record => record.key.length >= 40 ? `${record.key.substring(0, 40)}...` : `${record.key}`} />
        <BooleanField source="active" label="Active?" sortable={false} TrueIcon={CheckBoxIcon} FalseIcon={CheckBoxOutlineBlankIcon} textAlign="center" />
        <BooleanField source="navigation_enabled" label="Navigation?" sortable={false} TrueIcon={CheckBoxIcon} FalseIcon={CheckBoxOutlineBlankIcon} textAlign="center" />
        <BooleanField source="telematics_enabled" label="Telematics?" sortable={false} TrueIcon={CheckBoxIcon} FalseIcon={CheckBoxOutlineBlankIcon} textAlign="center" />
        <BooleanField source="eleos_eld_enabled" label="Eleos ELD?" sortable={false} TrueIcon={CheckBoxIcon} FalseIcon={CheckBoxOutlineBlankIcon} textAlign="center" />
        <BooleanField source="messages_enabled" label="Messages?" sortable={false} TrueIcon={CheckBoxIcon} FalseIcon={CheckBoxOutlineBlankIcon} textAlign="center" />
        <TripPlannerField label="Trip Planner?" {...props} sortable={false} textAlign="center" />
      </Datagrid>
    </List>
  );
}

export const TripSnapshotFormView = (props) => {
  const [activeTab, setActiveTab] = useState("Snapshots");
  const [mapClick, setMapClick] = useState(null)
  const [snapShotResults, setSnapShotResults] = useState(null);
  const [snapShotData, setSnapShotData] = useState(null);
  const [reasonCodes, setReasonCodes] = useState("");

  const handleChildTabChange = (tab) => { setActiveTab(tab); setMapClick(tab); }
  const handleClearMapClick = () => { setMapClick(null) }
  const handleUpdateSnapshotResults = (results) => { setSnapShotResults(results); }
  const handleSnapShotDataReceived = (data) => { setSnapShotData(JSON.parse(data)); }
  const handleReasonCodeReceived = (code) => { setReasonCodes(code); }
  const handleClearSnapShotData = () => { setSnapShotData(null); setReasonCodes(""); }

  return (
    <div>
      <Tabs mapClick={mapClick} onTabChange={handleClearMapClick}>
        <div label="Snapshots">
          <div style={{ textAlign: "center" }}>
            <p>Used to retrieve trip snapshots generated in the app per client</p>
            <TripSnapshotForm onTabChange={handleChildTabChange} onSnapShotDataReceived={handleSnapShotDataReceived} onReasonCodeReceived={handleReasonCodeReceived} onUpdateSnapShotResults={handleUpdateSnapshotResults} />
          </div>
        </div>
        <div label="Trip Planner">
          <div style={{ marginLeft: "5%" }}>
            <p>Used to view trip snapshots generated in the app per client</p>
            <TripPlannerMap snapShotData={snapShotData} reasonCodes={reasonCodes} onClearSnapShotData={handleClearSnapShotData} />
          </div>
        </div>
        <div label="Route Import">
          <div style={{ marginLeft: "10%" }}>
            <RouteImportForm />
          </div>
        </div>
      </Tabs>
    </div>
  );
}

export const ErrorReportView = (props) => {
  const { key, uuid } = useParams()

  return (
    <div>
      <div label="Error Report Tool">
        <div style={{ textAlign: "center" }}>
          <ErrorReportForm />
        </div>
      </div>
    </div>
  );
}

const DefaultPanelView = (props, source) => (
  <dl>
    <dt>{source}</dt>
    <dd><PrettyJsonField record={props.record} source={source} /></dd>
  </dl>
);

const FormFieldsPanel = (props) => {
  return (
    <ArrayField {...props} source="fields" title="Fields">
      <Datagrid bulkActionButtons={false}>
        <TextField source="code" />
        <TextField source="field_type_code" />
        <BooleanField source="visible_if_empty" />
        <BooleanField source="editable" />
        <PrettyJsonField source="metadata" />
      </Datagrid>
    </ArrayField>
  );
}

export const FormShow = () => {
  const { key, uuid } = useParams()
  const { data, isLoading } = useGetManyReference('forms', { id: key })
  if (isLoading || !data) return null
  const record = data.filter(r => r.id === uuid)

  return (
    <TabbedShowLayout record={record[0]} spacing={2} syncWithLocation={false}>
      <Tab label="Form Details">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="code" />
        <BooleanField source="enable_telematics_data" />
        <BooleanField source="display_in_send_message_list" />
        <DownloadField />
      </Tab>
      <Tab label="Fields">
        <FormFieldsPanel record={record[0]} />
      </Tab>
    </TabbedShowLayout>
  );
}

export const ScreenShow = () => {
  const { key, uuid } = useParams()
  const { data, isLoading } = useGetManyReference('screens', { id: key })
  if (isLoading) return null
  const record = data.filter(r => r.id === uuid)

  return (
    <TabbedShowLayout record={record[0]} spacing={2} syncWithLocation={false}>
      <Tab label="Screen Details">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="code" />
        <ArrayField source="definition" title="Definition">
          <Datagrid size="medium" bulkActionButtons={false} >
            <TextField source="type" />
            <TextField source="description" />
            <PrettyJsonField source="action" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="Definition Javascript">
        <TextField source="definition_javascript" />
      </Tab>
    </TabbedShowLayout>
  );
}

export const DashboardShow = () => {
  const { key, uuid } = useParams()
  const { data, isLoading } = useGetManyReference('dashboards', { id: key })
  if (isLoading || !data) return null
  const record = data.filter(r => r.id === uuid)

  return (
    <TabbedShowLayout record={record[0]} spacing={2} syncWithLocation={false}>
      <Tab label="Dashboard Details">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="code" />
        <DownloadField />
      </Tab>
    </TabbedShowLayout>
  );
}

export const MenuShow = () => {
  const { key, uuid } = useParams()
  const { data, isLoading } = useGetManyReference('menus', { id: key })
  if (isLoading || !data) return null
  const record = data.filter(r => r.id === uuid)

  return (
    <TabbedShowLayout record={record[0]} spacing={2} syncWithLocation={false}>
      <Tab label="Menu Details">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="code" />
        <DownloadField />
      </Tab>
      <Tab label="Menu Items">
        <MenuItemsPanel record={record[0]} />
      </Tab>
    </TabbedShowLayout>
  );
}

export const ActionShow = () => {
  const { key, uuid } = useParams()
  const { data, isLoading } = useGetManyReference('actions', { id: key })
  if (isLoading || !data) return null
  const record = data.filter(r => r.id.toString() === uuid)
  console.log(data)
  console.log(record)
  console.log(uuid)

  return (
    <TabbedShowLayout record={record[0]} spacing={2} syncWithLocation={false}>
      <Tab label="Action Details">
        <TextField source="id" />
        <TextField source="code" />
        <TextField source="title" />
        <TextField source="description" />
        <DownloadField />
      </Tab>
      <Tab label="Definition">
        <ActionDefinitionPanel record={record[0]} />
      </Tab>
    </TabbedShowLayout>
  );
}

const ActionDefinitionPanel = (props) => (
  DefaultPanelView(props, "definition")
);

const DashboardItemsPanel = ({ record, ...props }) => {
  if (typeof record["items"] === "string") {
    return DefaultPanelView(record, "items");
  }
  return (
    <ArrayField {...props} source="items" title="Dashboard Items">
      <Datagrid bulkActionButtons={false}>
        <TextField source="header" />
        <TextField source="type" />
        <TextField source="icon_ref" />
        <PrettyJsonField source="properties" />
      </Datagrid>
    </ArrayField>
  );
}

const MenuItemsPanel = (props) => {
  if (typeof props.record["items"] === "string") {
    return DefaultPanelView(props, "items");
  }
  return (
    <ArrayField {...props} source="items" title="Menu Items">
      <Datagrid bulkActionButtons={false}>
        <TextField source="label" />
        <TextField source="type" />
        <TextField source="icon_ref" />
        <PrettyJsonField source="properties" />
      </Datagrid>
    </ArrayField>
  );
}

// used for the client error api schema_error array
const SchemaErrorDetails = ({ record }) => {
  if (record["type"] == "schema") {
    return (
      <ArrayField {...record} source="schema_error">
        <Datagrid bulkActionButtons={false}>
          <TextField source="type" />
          <TextField source="path" />
        </Datagrid>
      </ArrayField>
    );
  }
  return (null);
}

export const ErrorShow = () => {
  const { key, uuid } = useParams()
  const { data, isLoading } = useGetManyReference('errors', { id: key })
  if (isLoading || !data) return null
  let record = data.filter(r => r.id === uuid)

  const clientErrorUrlStaging = `https://dashboard-staging.eleostech.com/#error/${uuid}`
  const clientErrorUrlProduction = `https://dashboard.driveaxleapp.com/#errors/${uuid}}`
  const ENVIRONMENT = (process && process.env && process.env.REACT_APP_ENVIRONMENT);
  if (ENVIRONMENT == "staging")
    record[0]["client_error_link"] = clientErrorUrlStaging
  else
    record[0]["client_error_link"] = clientErrorUrlProduction

  return (
    <TabbedShowLayout record={record[0]} syncWithLocation={false}>
      <Tab label="Error Details">
        <TextField source="username" />
        <TextField source="request_headers" />
        <TextField source="request_url" />
        <TextField label="Error Type" source="type" />
        <TextField source="error_details" />
        <SchemaErrorDetails record={record[0]} />
        <TextField label="Client Error Link" source="client_error_link" />
      </Tab>
      <Tab label="Web Service Response">
        <TextField source="response" />
      </Tab>
    </TabbedShowLayout>
  );
}

const EmailVerificationCreateToolbar = () => {
  return (
    <Toolbar>
      <SaveButton label="Send" icon={<SendIcon />} />
    </Toolbar>
  );
}

const EmailVerificationPreview = ({ message }) => {
  return (
    <Box paddingLeft="8px" sx={{ width: "35%" }}>
      <Card>
        <MaterialTabs>
          <MaterialTab label="Email Preview:" disabled />
        </MaterialTabs>
        <Divider />
        <table
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          class="content"
          style={{
            width: "70%",
            color: "#444",
            fontSize: "12px",
            paddingTop: "23px",
            paddingBottom: "23px",
            textAlign: "left",
            margin: "0 auto",
            fontFamily: "monospace",
          }}
          bgcolor="white"
          width="100%"
        >
          <tr>
            <td class="cta" colspan="3">
              <h2
                style={{
                  fontFamily: "monospace",
                  fontSize: "30px",
                  color: "#000",
                  fontWeight: "normal",
                  letterSpacing: "0",
                  margin: "0 0 13px",
                  padding: "0",
                }}
              >
                Verify Your Support Request
              </h2>
              <p style={{ margin: "0 0 13px", padding: "0" }}>
                Eleos support received the following request from your
                email address:
              </p>

              <p
                style={{
                  margin: "0 0 13px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                }}
              >
                {message}
              </p>

              <p>
                If this was you, click the link below to verify the request.
              </p>

              <p style={{ margin: "0 0 13px", padding: "0" }}>
                <a href="http://www.example.com" target="_blank">
                  Verify request
                </a>
              </p>
            </td>
          </tr>
        </table>
      </Card>
    </Box>
  );
}

export const EmailVerificationCreate = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const notify = useNotify();
  const redirect = useRedirect();
  const [message, setMessage] = useState("");

  const onSuccess = (data) => {
    notify("Email verification request sent");
    redirect("show", "backend_users", data.id);
  };

  if (email === null) {
    return <NotFound />;
  } else {
    return (
      <Create
        title={`Request verification from ${email}`}
        mutationOptions={{ onSuccess }}
        aside={<EmailVerificationPreview message={message} />}
      >
        <SimpleForm toolbar={<EmailVerificationCreateToolbar />}>
          <TextInput
            source="email"
            disabled
            defaultValue={email}
            validate={required()}
          />
          <TextInput
            source="message"
            multiline
            minRows="4"
            validate={required()}
            style={{ width: "60%" }}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
        </SimpleForm>
      </Create>
    );
  }
};

const BackendUserShowActions = () => {
  const record = useRecordContext();
  const createPath = useCreatePath();
  const isTOSAccepted = record.accepted_tos === true;
  return (
    <TopToolbar>
      <EditButton record={record} />
      <MaterialButton
        id="verify-button"
        color="primary"
        component={RouterLink}
        to={
          createPath({
            resource: "email_verifications",
            type: "create",
          }) + `?email=${encodeURIComponent(record.id)}`
        }
        disabled={!isTOSAccepted}
      >
        <MarkEmailReadIcon
          style={{ fontSize: "1.25rem", paddingRight: "0.5rem" }}
        />
        Request Verification
      </MaterialButton>
      <MigrateButton />
    </TopToolbar>
  );
};

export const BackendUserEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const backendUserTransform = (data) => {
  return { allow_ftp_downloads: data.allow_ftp_downloads };
};

export const BackendUserEdit = () => {
  const { permissions } = usePermissions();
  return (
    <Edit transform={(data) => backendUserTransform(data)} redirect="show">
      <SimpleForm
        toolbar={renderIfPermissions(permissions, "backend_users", "write", <BackendUserEditToolbar />)}
        mode="onBlur"
        destroyOnUnregister={true}
        sanitizeEmptyValues={true}
      >
        <BooleanInput source="allow_ftp_downloads" label="Allow FTP Downloads" />
      </SimpleForm>
    </Edit>
  );
};

export const BackendUserShow = (props) => {
  const { permissions } = usePermissions()
  const { record } = useShowController()
  const [searchParams] = useSearchParams()

  if (record === undefined) {
    return <></>;
  } else {
    return (
      <>
        {searchParams.get("success") === "" ?
          <div style={{ "padding-top": "10px" }}>
            <Alert severity="warning">
              Backend user migration will NOT take effect until approval by a second administrator
            </Alert>
          </div>
          : <></>}
        <Show  {...props} actions={renderIfPermissions(permissions, "backend_users", "write", <BackendUserShowActions />)}>
          <TabbedShowLayout>
            <Tab label="Details">
              <TextField source="first_name" />
              <TextField source="last_name" />
              <TextField source="email" />
              <DateField source="confirmed_at" showTime />
              <DateField source="invitation_sent_at" showTime />
              <BooleanField label="Accepted TOS" source="accepted_tos" />
              <DateField label="Verification requested at" source="verification_sent_at" showTime />
              <DateField source="verified_at" showTime />
              <TextField source="active" />
              <TextField source="allow_ftp_downloads" />
              {record.confirmed_at === null && renderIfPermissions(permissions, "backend_users", "write",
                <ResendInviteButton record={record} {...props} />
              )}
              {record.active === true && record.confirmed_at === null && renderIfPermissions(permissions, "backend_users", "write",
                <ActiveButton record={record} {...props} />
              )}
              {record.active === false && renderIfPermissions(permissions, "backend_users", "write",
                <ActiveButton record={record} {...props} />
              )}
            </Tab>
            <Tab label="Roles">
              <ArrayField source="roles" title="Roles">
                <Datagrid {...props} bulkActionButtons={false}>
                  <TextField source="name" />
                  <TextField source="code" />
                  <DateField source="created_at" showTime />
                  <TextField source="description" />
                  {renderIfPermissions(permissions, "backend_users", "write",
                    <RoleDeleteButton record={record} {...props} />
                  )}
                </Datagrid>
              </ArrayField>
              {renderIfPermissions(permissions, "backend_users", "write",
                <AddRoleButton record={record} />
              )}
            </Tab>
            <Tab label="Sendgrid Status">
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <BlockDeleteButton record={record} />
                <BounceDeleteButton record={record} />
              </div>
              <ArrayField source="sendgrid_status" title="Sendgrid Status">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="email" />
                  <TextField source="created" />
                  <TextField source="reason" />
                  <TextField source="type" />
                </Datagrid>
              </ArrayField>
            </Tab>
            <Tab label="Migration drafts">
              <BackendUserMigrationDraftsField />
            </Tab>
            <Tab label="Email verification history">
              <BackendUserEmailVerificationsField />
            </Tab>
          </TabbedShowLayout>
        </Show>
      </>
    );
  }
}


const encodeFormData = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join('&')
}

const buttonStyle = {
  display: "inline-block",
  margin: "0 0.3em 0.3em 0",
  borderRadius: "2em",
  boxSizing: "border-box",
  textDecoration: "none",
  fontFamily: 'Roboto',
  fontWeight: "300",
  backgroundColor: "#FFFFFF",
  color: "#4eb5f1",
  textAlign: "center",
  cursor: "pointer"
}
const DownloadButton = (props) => {
  const url = props.url;
  if (url === "null" || url === undefined) { return (<div></div>); }
  return (
    <h4> Download <a href={url} download><button class="downloadButton" style={buttonStyle}> <label htmlFor="downloadfile"> <GetAppIcon /> </label> </button></a></h4>
  );
}
const AltDashboardDownloadButton = (props) => {
  const record = useRecordContext(props);
  const Image = get(record, "Image");

  if (Image === "null" || Image === undefined) { return (<div></div>); }
  return (
    <h4> Download <a href={Image} download><button className="downloadButton" style={buttonStyle}> <label htmlFor="downloadfile"> <GetAppIcon /> </label> </button></a></h4>
  );
}

const renderIfApp = (id, toRender) => {
  const client = useGetOne('clients', { id: id });
  const live = useGetOne('apps', { id: id });
  let key = client.data?.live_client_key || id
  const sandbox = useGetOne('apps', { id: key });

  if (live.data || sandbox.data) { return toRender; }
  return null
}


const AltDashboardLogos = ({ permissions, ...combineddata }) => {

  if (!combineddata.alt_dashboard_logo || combineddata.alt_dashboard_logo.length === 0) {
    return <p>No alternate dashboard images available</p>;
  }

  const renamedHeader = combineddata.alt_dashboard_logo.map(item => {
    const { url, ...rest } = item;
    return { ...rest, Image: url };
  });

  return (
    <div>
      <List
        hasCreate={false} exporter={false}>
        <Datagrid data={renamedHeader} bulkActionButtons={false}>
          <TextField source="filename" />
          <ImageField
            title="filename"
            source="Image"
            style={{ margin: '0.5em 0 0 0' }}
          />
          <AltDashboardDownloadButton />
        </Datagrid>
      </List>
    </div>
  );
}

const AppAssets = ({ permissions, ...props }) => {

  const { id } = useParams();
  const { data, error, isLoading } = useGetOne('app_assets', { id })
  const { data: altdashboardlogodata, error: altDashboardLogosError, isLoading: altDashboardLogosLoading, total } = useGetManyReference('alt_dashboard_logos', { id })

  if (isLoading || altDashboardLogosLoading) {
    return (
      <TabbedShowLayout syncWithLocation={false}>
        <Tab label="App Icon">
          <h4>Loading Current App Icon</h4>
        </Tab>
        <Tab label="App Dashboard">
          <h4>Loading Current Dashboard Logo</h4>
        </Tab>
        <Tab label="App Login Logo">
          <h4>Loading Current Login Logo</h4>
        </Tab>
        <Tab label="Alternate Dashboard Logos">
          <h4>Loading Alternate Dashboard Logos</h4>
        </Tab>
      </TabbedShowLayout>
    );
  }

  const combineddata = {
    app_icon_url: data?.app_icon_url ?? null,
    dashboard_logo_url: data?.dashboard_logo_url ?? null,
    login_logo_url: data?.login_logo_url ?? null,
    id: data?.id ?? null,
    alt_dashboard_logo: altdashboardlogodata ?? null,
  };

  return (

    <TabbedShowLayout syncWithLocation={false}>
      <Tab label="App Icon">
        <AppAssetUploadComponent {...combineddata} appAssetName="app-icon" />
        <h4>Current App Icon</h4>
        <img src={combineddata.app_icon_url} alt="no image" height="256"></img>
        <DownloadButton url={combineddata.app_icon_url} />
      </Tab>
      <Tab label="App Dashboard">
        <AppAssetUploadComponent {...combineddata} appAssetName="dashboard-logo" />
        <h4>Current Dashboard Logo</h4>
        <img src={combineddata.dashboard_logo_url} alt="no image" height="256"></img>
        <DownloadButton url={combineddata.dashboard_logo_url} />
      </Tab>
      <Tab label="App Login Logo">
        <AppAssetUploadComponent {...combineddata} appAssetName="login-logo" />
        <h4>Current Login Logo</h4>
        <img src={combineddata.login_logo_url} alt="no image" height="256"></img>
        <DownloadButton url={combineddata.login_logo_url} />
      </Tab>
      <Tab label="Alternate Dashboard Logos">
        <AltDashboardUploadComponent {...combineddata} />
        <h4>Current Alternate Dashboard Logos</h4>
        <AltDashboardLogos {...combineddata} />
      </Tab>
    </TabbedShowLayout>
  );
}

export const ClientShowActions = () => {
  const notify = useNotify()
  const { permissions } = usePermissions();
  const data = useRecordContext();

  const handleTestNotifications = (e) => {
    SendMessageToAppstore(data.key);
  }

  const SendMessageToAppstore = (clientKey) => {
    const apiUrl = (window.frameElement && window.frameElement.getAttribute("data-api-url")) ||
      (process && process.env && process.env.REACT_APP_API_URL)

    var data = { body: "Support: Testing Notifications", direction: "outbound", alert: true, username: "APPSTORE", message_type: "text", sound: "default", text_to_speech_announcement: "Support: Testing Text To Speech" }
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-url-encoded;charset=UTF-8',
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
        "eleos-admin-client-key": `${clientKey}`
      },
      body: encodeFormData(data)
    };

    var handle = uuid();
    const url = `${apiUrl}/api/v1/messages/${handle}`;

    const handleErrors = (response) => {
      if (!response.ok) {
        console.log(response);
        notify(`Failed sending test notification message to ${clientKey}. Status: ${response.status}`, 'warning');
      }
      else {
        notify("Message sent successfully", 'success');
      }
      return response;
    }

    notify(`Sending test notification to APPSTORE user for client: ${clientKey}`, 'info')

    fetch(url, options)
      .then(response => {
        handleErrors(response)
      })
  }

  if (data != null) {
    return renderIfPermissions(permissions, "clients", "write",
      <TopToolbar>
        <MaterialButton color="primary" onClick={e => handleTestNotifications(e)} style={{ fontSize: '0.8125rem', padding: '.15rem' }}><MessageIcon style={{ fontSize: '1.25rem', paddingRight: '0.5rem' }} />Test Notifications</MaterialButton>
        <EditButton record={data} />
      </TopToolbar>
    );
  }
  else {
    return (<div></div>);
  }
}


export const ClientRetentionPolicyList = () => {
  const { record } = useShowContext()
  const policies = record.retention_policies === undefined ? [] : record.retention_policies[0]

  return (
    <div style={{ marginTop: "12px" }}>
      <InputLabel shrink>Retention Policies:</InputLabel>
      <div style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.12)", paddingLeft: "15px", marginTop: "15px" }}>
        {Object.keys(policies).map(dataKey => {
          const value = typeof policies[dataKey] === "boolean" ? policies[dataKey].toString() : policies[dataKey]

          return (
            <div key={dataKey}>
              <div style={{ marginTop: "8px", color: "rgba(0, 0, 0, 0.54)" }}>
                <InputLabel shrink>{dataKey.charAt(0).toUpperCase() + dataKey.slice(1).replaceAll("_", " ")}</InputLabel>
              </div>
              <div style={{ paddingTop: "8px", minHeight: "10px" }}>
                <Typography variant="body2">{value === null || value === undefined ? "" : value}</Typography>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const IntegrationMetadataFormatField = () => {
  const { id } = useParams()
  const {
    data, error, isLoading
  } = useGetOne("integration_metadata_formats", {
    id,
    meta: { account_type: "client" },
  });

  if (error) return <></>;
  if (isLoading) return <></>;

  return (
    <div style={{ margin: "0", marginTop: "8px" }}>
      <Typography
        variant="body1"
        style={{ fontSize: "0.75em", marginBottom: "0.2em", color: "rgba(0, 0, 0, 0.6" }}
      >
        Integration Metadata Format
      </Typography>
      <Typography variant="body2">
        {data.description}
      </Typography>
    </div>
  );
};

export const ClientShow = (props) => {
  const { permissions } = usePermissions();
  const { id } = useParams()
  const { record } = useShowController()

  return (

    <Show actions={<ClientShowActions permissions={permissions} />} {...props}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" {...props} />} spacing={2} style={{ paddingBottom: "16px" }} syncWithLocation={false} uuid={id}>
        <Tab label="Client Information" path="info">
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Tab label="info">
                <TextField source="name" />
                <TextField source="key" label="Client Key" />
                <TextField source="role" label="Client Type" />
                <BooleanField source="active" />
                <DateField source="created_at" />
                <TextField source="eleos_platform_key" />
                <TextField source="api_key" />
                <TextField source="live_client_key" />
                <BooleanField source="navigation_enabled" />
                <BooleanField source="messages_enabled" />
                <BooleanField source="telematics_enabled" />
                <TextField source="trip_planner" />
                <BooleanField source="eleos_eld_enabled" />
                <BooleanField source="location_updates_enabled" />
                <BooleanField source="votd_enabled" />
                <BooleanField source="news_enabled" />
                <BooleanField source="form_editor_enabled" />
                <BooleanField source="motd_enabled" />
                <BooleanField source="rescans_enabled" />
                <BooleanField source="disable_embedded_geotab" label="Disable Embedded Geotab" />
                <BooleanField source="disable_drivewyze_sdk" label="Disable Drivewyze SDK" />
                <TextField source="tmw_client_identifier" />
                <TextField label="Inmotion speed threshold (m/s)" source="inmotion_speed_threshold" />
                <TextField label="Inmotion timeout (s)" source="inmotion_timeout" />
                <TextField source="inmotion_lock_message" />
                <TextField source="inmotion_override_message" />
                <TextField source="hubspot_customer_identifier" />
                <TextField source="hubspot_deal_identifier" />
                <TextField source="min_good_quality" label="Document Quality Threshold - Gray Warning" />
                <TextField source="max_bad_quality" label="Document Quality Threshold - Red Warning" />
                <TextField source="app_store_identifier" />
                {renderIfPermissions(permissions, "client_groups", "read_only", 
                  <ReferenceField label="Client Group Key" source="client_group_key" reference="client_groups" link="show">
                    <TextField source="key" />
                  </ReferenceField>
                )}
                <TextField source="gcm_app.gcm_push_notification_key" />
                <TextField source="apns_app.private_key" />
                {renderIfPermissions(permissions, "billing", "read_only", 
                  <ReferenceField label="Billing Account Name" source="billing_account_id" reference="billing_accounts" link="show">
                    <TextField source="name" />
                  </ReferenceField>
                )}
                <TextField source="billing_account_id" label="Billing Account UUID" />
                <BooleanField source="is_usage_billable" />
                <ReferenceField label="Guest Client Name" source="guest_client_key" reference="clients" link="show">
                  <TextField source="name" />
                </ReferenceField>
                {renderIfPermissions(permissions, "apps", "read_only", 
                  <ReferenceField label="Pledges (Please go to associated app to find pledge tokens)" source="key" reference="apps" link="show">
                    <TextField source="app_name" />
                  </ReferenceField>
                )}
                <TextField source="id" />
                <WrapperField label="Api Service Config">
                  <div style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.12)", paddingLeft: "15px", marginTop: "15px", display: "flex", flexDirection: "column" }}>
                    <Labeled>
                      <BooleanField source="api_service_config.Truck Status Service" label="Truck Status Service" />
                    </Labeled>
                    <Labeled>
                      <BooleanField source="api_service_config.Trip Change Service" label="Trip Change Service" />
                    </Labeled>
                    <Labeled>
                      <BooleanField source="api_service_config.TODO Service" label="TODO Service" />
                    </Labeled>
                    <Labeled>
                      <BooleanField source="api_service_config.Routing Schema Service" label="Routing Schema Service" />
                    </Labeled>
                    <Labeled>
                      <BooleanField source="api_service_config.Payroll Service" label="Payroll Service" />
                    </Labeled>
                    <Labeled>
                      <BooleanField source="api_service_config.Messaging Service" label="Messaging Service" />
                    </Labeled>
                    <Labeled>
                      <BooleanField source="api_service_config.Load Service" label="Load Service" />
                    </Labeled>
                    <Labeled>
                      <BooleanField source="api_service_config.Driver Status Service" label="Driver Status Service" />
                    </Labeled>
                    <Labeled>
                      <BooleanField source="api_service_config.Authentication Service" label="Authentication Service" />
                    </Labeled>
                  </div>
                </WrapperField>
                <ClientRetentionPolicyList />
                <IntegrationMetadataFormatField />
              </Tab>
            </Grid>
            {renderIfPermissions(permissions, "clients", "write",
              <Grid item xs={5}>
                <Changelog from="clients" />
              </Grid>
            )}
          </Grid>
        </Tab>
        {renderIfPermissions(permissions, "client_assets", "read_only",
          <Tab label="Client Assets" path="assets">
            <TabbedShowLayout syncWithLocation={false}>
              <Tab label="Forms" path="forms">
                {renderIfPermissions(permissions, "client_assets", "write",
                  <FileUploadComponent asset={"forms"} />
                )}
                <ReferenceManyField label="Forms" reference="forms" target="key">
                  <Datagrid rowClick={(uuid) => `/forms/${id}/${uuid}`} expand={<FormFieldsPanel record={record} />} bulkActionButtons={false}>
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="code" />
                    <BooleanField source="enable_telematics_data" />
                    <BooleanField source="display_in_send_message_list" />
                    <DownloadField />
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
              <Tab label="Screens" path="screens">
                {renderIfPermissions(permissions, "client_assets", "write",
                  <FileUploadComponent asset={"screens"} />
                )}
                <ReferenceManyField label="Screens" reference="screens" target="key" >
                  <Datagrid rowClick={(uuid) => `/screens/${id}/${uuid}`}>
                    <TextField source="id" />
                    <TextField source="title" />
                    <TextField source="code" />
                    <DownloadField />
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
              <Tab label="Dashboards" path="dashboards">
                {renderIfPermissions(permissions, "client_assets", "write",
                  <FileUploadComponent asset={"dashboards"} />
                )}
                <ReferenceManyField label="Dashboards" reference="dashboards" target="key">
                  <Datagrid rowClick={(uuid) => `/dashboards/${id}/${uuid}`} expand={<DashboardItemsPanel record={record} {...props} />} bulkActionButtons={false}>
                    <TextField source="id" />
                    <TextField source="title" />
                    <TextField source="code" />
                    <DownloadField />
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
              <Tab label="Menus" path="menus">
                {renderIfPermissions(permissions, "client_assets", "write",
                  <FileUploadComponent asset={"menus"} />
                )}
                <ReferenceManyField label="Menus" reference="menus" target="key">
                  <Datagrid rowClick={(uuid) => `/menus/${id}/${uuid}`} expand={<MenuItemsPanel />} bulkActionButtons={false}>
                    <TextField source="id" />
                    <TextField source="title" />
                    <TextField source="code" />
                    <DownloadField />
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
              <Tab label="Actions" path="actions">
                {renderIfPermissions(permissions, "client_assets", "write",
                  <FileUploadComponent asset={"actions"} />
                )}
                <ReferenceManyField label="Actions" reference="actions" target="key">
                  <Datagrid rowClick={(uuid) => `/actions/${id}/${uuid}`} expand={<ActionDefinitionPanel />} bulkActionButtons={false}>
                    <TextField source="id" />
                    <TextField source="code" />
                    <TextField source="title" />
                    <TextField source="description" />
                    <DownloadField />
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
            </TabbedShowLayout>
          </Tab>
        )}
        {renderIfPermissions(permissions, "apps", "read_only", 
          renderIfApp(id, renderIfPermissions(permissions, "clients", "write",
            <Tab label="App Assets" path="app_assets">
              <AppAssets {...props} permissions={permissions} />
            </Tab>)
        ))}
        <Tab label="User Search" path="user">
          <UserSearchForm record={record} />
        </Tab>
        {renderIfPermissions(permissions, "backend_users", "read_only", 
          <Tab label="Backend Users" path="backend_users">
            <ReferenceManyField label="Backend Users" reference="backend_users" target="key">
              <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="first_name" />
                <TextField source="last_name" />
                <TextField source="email" />
                <TextField source="confirmed_at" />
                <TextField source="invitation_sent_at" />
                <TextField source="active" />
                <TextField source="allow_ftp_downloads" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        )}
        {renderIfPermissions(permissions, "client_assets", "write",
          <Tab label="Error Console">
            <ReferenceManyField label="Errors" reference="errors" target="key">
              <Datagrid rowClick={(uuid) => `/errors/${id}/${uuid}`} bulkActionButtons={false}>
                <TextField source="service_name" />
                <DateField source="created_at" showTime />
                <TextField source="username" />
                <TextField source="http_status" />
                <TextField source="request_url" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        )}
        {renderIfPermissions(permissions, "apps", "write",
          <Tab label="Trigger Builds">
            <SimpleForm toolbar={<></>}>
              <TriggerBuildForm record={record} />
            </SimpleForm>
          </Tab>
        )}
        {renderIfPermissions(permissions, "apps", "read_only", 
          <Tab label="Client Apps">
            <ReferenceManyField reference="apps" target="key">
              <Datagrid>
                <TextField source="client_key" label="Client Key" />
                <TextField source="app_name" label="App Name" />
                <BooleanField source="is_billable" label="Billable" />
                {renderIfPermissions(permissions, "apps", "write", <EditButton />)}
                <ShowButton />
              </Datagrid>
            </ReferenceManyField>
            {renderIfPermissions(permissions, "apps", "write", <CreateNewAppButton />)}
          </Tab>)}
        {renderIfPermissions(permissions, "documents", "read_only",
          <Tab label="Document Types" path="document_types">
            <ShowDocTypes record={record} />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
}

const ClientEditToolbar = () => {
  const { id } = useParams()

  return (
    <Toolbar>
      <SaveButton disabled={window.location.hash === `#/clients/${id}/deactivate`} />
    </Toolbar>
  )
};

const CreateNewAppButton = () => {
  const record = useRecordContext();
  return (
    <MaterialButton
      color="primary"
      component={Link}
      to={{
        pathname: '/apps/create/',
        state: { record: { client_key: record.key } },
      }}
      label="Create App"
      size="small"
      sx={{ marginTop: "5px" }}
    > Create App
    </MaterialButton>
  )
}

const difference = (oldObj, newObj) => {
  let diff = "";
  Object.keys(oldObj).forEach(key => {
    if (typeof oldObj[key] === 'object') {
      if (JSON.stringify(oldObj[key]) !== JSON.stringify(newObj[key])) {
        diff = diff.concat(`${key}:`.padEnd(30) + `${JSON.stringify(oldObj[key])} -> ${JSON.stringify(newObj[key])}\n`.padStart(30))
      }
    }
    else if (oldObj[key] !== newObj[key]) {
      diff = diff.concat(`${key}: `.padEnd(30) + `${oldObj[key]} -> ${newObj[key]}\n`.padStart(30))
    }
  });

  return diff;
};

export const ClientEdit = (props) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { id } = useParams();
  const [retentionPolicyChange] = useUpdate();
  const { data, error, isLoading } = useGetOne('clients', { id })
  const {
    data: metadataFormatData,
    error: metadataFormatError,
    isLoading: metadataFormatIsLoading,
  } = useGetOne("integration_metadata_formats", {
    id,
    meta: { account_type: "client" },
  });
  const [updateMetadataFormat] = useUpdate();
  if (isLoading || metadataFormatIsLoading) return <Loading />;
  if (error || metadataFormatError) return <Error />;
  if (!data) return null;

  data.integration_metadata_format = metadataFormatData.name;

  const update = (data) => {
    return data;
  }

  const emptyStringsToNull = data => {
    let sanitizedData = data
    Object.keys(data).forEach(key => {
      if (typeof data[key] === "string" && data[key] === "") data[key] = null
      if (typeof data[key] === "object" && data[key]) emptyStringsToNull(data[key])
    })
    return sanitizedData
  }

  const transform = (rawData) => {
    const newData = emptyStringsToNull(rawData)
    const diff = difference(data, newData)
    console.log(data, newData, diff)
    if (diff.length === 0) {
      alert("No Changes.")
      return;
    }
    else {
      if (confirm(`Please ensure these changes are correct:\n\n${diff}`)) {
        //If retention policies need update, send seperate api request from client update
        if ((data.retention_policies[0].enabled != newData.retention_policies[0].enabled) ||
          (data.retention_policies[0].purge_after_hours != newData.retention_policies[0].purge_after_hours) ||
          (data.retention_policies[0].delete_after_hours != newData.retention_policies[0].delete_after_hours)) {
          retentionPolicyChange(
            "retention_policies", {
            id: id,
            data: { purge_after_hours: newData.retention_policies[0].purge_after_hours, delete_after_hours: newData.retention_policies[0].delete_after_hours, enabled: newData.retention_policies[0].enabled },
          }
          );
          //Remove retention policies from client update sequence and continue as before
          delete newData.retention_policies;
          if (diff.length === 0) {
            alert("No Changes.")
            return;
          }
        }
        if (data.billing_account_id !== newData.billing_account_id) {
          var response = prompt(`Are you sure you want to change the billing account associated with ${data.name}?\nPlease enter the client key exactly if you wish to continue.`)
          while (response !== null && response !== data.key) {
            var response = prompt("Client key was entered incorrectly.\nPlease enter the client key exactly.")
          }
          if (response === data.key) {
            if (newData.integration_metadata_format !== data.integration_metadata_format) {
              updateMetadataFormat(
                "integration_metadata_formats",
                {
                  id: id,
                  data: {
                    integration_metadata_format: newData.integration_metadata_format,
                    account_type: "client"
                  },
                  previousData: {}
                }
              );
            }
            return update(newData);
          }
        }
        else {
          if (newData.integration_metadata_format !== data.integration_metadata_format) {
            updateMetadataFormat(
              "integration_metadata_formats",
              {
                id: id,
                data: {
                  integration_metadata_format: newData.integration_metadata_format,
                  account_type: "client"
                },
                previousData: {}
              }
            );
          }
          return update(newData);
        }
      }
      else {
        return;
      }
    }
  };

  const validateNumberInput = minValue(0, 'Value cannot be less than 0')

  const validateRetentionRange = (value) => {
    if (value < 168) {
      return `Value must be between 168 and 4320`;
    }
    if (value > 4320) {
      return `Value must be between 168 and 4320`;
    }
    return undefined;
  };

  if (data.active === true) {
    return (
      <Edit redirect="show" transform={transform} mutationMode="pessimistic" aside={<ClientEditDoc/>}>
        <TabbedForm toolbar={< ClientEditToolbar />}>
          <FormTab label="Client Configuration">
            <TextInput source="name" label="Name" validate={required()} />
            <TextInput source="key" label="Client Key" disabled />
            <ReferenceInput source="live_client_key" reference="clients" >
              <AutocompleteInput optionText="key" suggestionLimit={10} />
            </ReferenceInput>
            <ReferenceInput source="client_group_key" reference="client_groups"  >
              <AutocompleteInput optionText="key" suggestionLimit={10} />
            </ReferenceInput>
            <ReferenceInput label="Billing Account" source="billing_account_id" reference="billing_accounts">
              <AutocompleteInput optionText="name" optionValue="external_id" suggestionLimit={10} validate={data.role !== "sandbox" && required()}></AutocompleteInput>
            </ReferenceInput>
            <ReferenceInput label="Guest Client" source="guest_client_key" reference="clients" >
              <AutocompleteInput optionText="name" optionValue="id"></AutocompleteInput>
            </ReferenceInput>
            <SelectInput source="trip_planner" label="Trip Planner"
              choices={[
                { id: 'disabled', name: 'disabled' },
                { id: 'enabled', name: 'enabled' },
                { id: 'optional', name: 'optional' }
              ]}
            />
            <BooleanInput source="navigation_enabled" label="Navigation" />
            <BooleanInput source="messages_enabled" label="Messages" />
            <BooleanInput source="location_updates_enabled" label="Location Updates" />
            <BooleanInput source="rescans_enabled" label="Rescans" />
            <NullableBooleanInput source="disable_embedded_geotab" label="Disable Embedded Geotab" falseLabel="false" trueLabel="true" />
            <NullableBooleanInput source="disable_drivewyze_sdk" label="Disable Drivewyze SDK" falseLabel="false" trueLabel="true" />

            <NumberInput source="inmotion_speed_threshold" label="Inmotion Speed Threshold (m/s)" validate={validateNumberInput} />
            <NumberInput source="inmotion_timeout" label="Inmotion Timeout (s)" validate={validateNumberInput} />
            <TextInput source="inmotion_lock_message" label="Inmotion Lock Message" />
            <TextInput source="inmotion_override_message" label="Inmotion Override Message" />
            <NumberInput source="min_good_quality" label="Gray Warning Threshold" validate={validateNumberInput} />
            <NumberInput source="max_bad_quality" label="Red Warning Threshold" validate={validateNumberInput} />
            <TextInput source="hubspot_company_identifier" label="Hubspot Company ID" />
            <TextInput source="hubspot_deal_identifier" label="Hubspot Deal ID" />
            <TextInput source="app_store_identifier" label="App Store Identifier" />
            <TextInput source="gcm_app.gcm_push_notification_key" label="GCM Push Notification Key" />
            <TextInput source="apns_app.private_key" label="APNS Private Key" />
            <TextInput source="apns_app.certificate" label="APNS Certificate" />
            <InputLabel label="retention_policy_title" shrink htmlFor="retention_policy_title">Retention Policies:</InputLabel>
            <div style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.12)", paddingLeft: "15px", marginTop: "15px" }}>
              <div>
                <NumberInput source="retention_policies[0].purge_after_hours" label="Purge after hours" id="Purge after hours" validate={validateRetentionRange} />
              </div>
              <div>
                <NumberInput source="retention_policies[0].delete_after_hours" label="Delete after hours" id="Delete after hours" validate={validateRetentionRange} />
              </div>
              <div>
                <BooleanInput source="retention_policies[0].enabled" label="Enabled" />
              </div>
            </div>
            <ReferenceInput source="integration_metadata_format" reference="integration_metadata_formats">
              <AutocompleteInput
                source="description"
                label="Integration metadata format"
                defaultValue={data.integration_metadata_format}
                validate={required()}
                optionText="description"
              />
            </ReferenceInput>
          </FormTab>
          <FormTab label="Deactivate" path="deactivate">
            <div style={{ width: "100%", padding: "25px", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography variant="h6"><b>Warning - If you deactivate this client:</b></Typography>
              <p>
                <Typography variant="body1">• Users will be logged out, will be unable to log in to the app or platform dashboard, and will be unable to use our API.</Typography>
                <Typography variant="body1">• The group key for this client will not be preserved.</Typography>
                <Typography variant="body1">• If you decide at a later time to reactivate this client, the group key must be re-entered.</Typography>
              </p>
            </div>
            <DeactivateClientButton />
            <div style={{ height: "100px" }}></div>
          </FormTab>
        </TabbedForm>
      </Edit>
    )
  } else {
    return (
      <div>
        <Edit>
          <ActivateBillableClientButton />
        </Edit>
        <Edit hasShow={false} >
          <ActivateNonBillableClientButton />
        </Edit>
      </div>
    )
  }
};


const clientIDValidators = [required(), regex(/^[a-z]+(-[a-z]+)*$/, "The client key may only contain lowercase letters and hyphens, and it may not start or end with a hyphen."),];

export const ClientCreate = () => {

  const transform = (data) => {
    return { ...data, is_usage_billable: (data.billing_account === null || data.billing_account === undefined ? false : true) }
  };
  const redirect = useRedirect();
  const [isLiveKeyNullWarningOpen, setIsLiveKeyNullWarningOpen] = useState(false);
  const [isLiveKeySetWarningOpen, setIsLiveKeySetWarningOpen] = useState(false);
  const [isHubspotDealNullWarningOpen, setIsHubspotDealIsNullWarningOpen] = useState(false)
  const [userKeyInput, setUserKeyInput] = useState("");
  const [formValues, setFormValues] = useState({});

  // Handle the form submission and redirection
  const submitForm = async () => {
    const data = transform(formValues);
    await dataProvider('CREATE', 'clients', { data }, `Bearer ${localStorage.getItem('token')}`);
    redirect(`/clients/${data.key}/show`);
  };
  // Handle the confirmation of the warning
  const handleConfirmWarning = (action) => {
    if (action === "null") {
      setIsLiveKeyNullWarningOpen(false);
      if (!isHubspotDealNullWarningOpen) {
        submitForm();
      }
    } else if (action === "LiveClientKeyIsSet") {
      setIsLiveKeySetWarningOpen(false);
      if (!isHubspotDealNullWarningOpen) {
        submitForm();
      }
    }
  };

  const handleNullDealConfirmation = () => {
    setIsHubspotDealIsNullWarningOpen(false);
    if (!isLiveKeyNullWarningOpen && !isLiveKeySetWarningOpen) {
      submitForm();
    }
  };

  const handleNullDealCancel = () => {
    setIsHubspotDealIsNullWarningOpen(false);
    setIsLiveKeySetWarningOpen(false);
    setIsLiveKeyNullWarningOpen(false);
  };

  // Handle the cancellation of the warning
  const handleCancelWarning = (action) => {
    if (action === "null") {
      setIsLiveKeyNullWarningOpen(false);
      setIsHubspotDealIsNullWarningOpen(false);

    } else if (action === "LiveClientKeyIsSet") {
      setIsLiveKeySetWarningOpen(false);
      setIsHubspotDealIsNullWarningOpen(false);

    }
  };
  // Handle the form submission when creating a client
  const handleSubmit = (values) => {
    setFormValues(values);
    if (!values.live_client_key) setIsLiveKeyNullWarningOpen(true);
    else if (values.live_client_key) setIsLiveKeySetWarningOpen(true);
    if (values.hubspot_deal_identifier == null || values.hubspot_deal_identifier == "") setIsHubspotDealIsNullWarningOpen(true);
  };
  return (
    <Create transform={transform} aside={<ClientCreateDoc/>}>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput source="name" label="Client name" validate={required()} />
        <TextInput source="key" label="Client key" validate={clientIDValidators} />
        <SelectInput source="role" label="Client Type" defaultValue={null}
          choices={[
            { id: null, name: 'No Type' },
            { id: 'live', name: 'Live' },
            { id: 'sandbox', name: 'Sandbox' },
            { id: 'guest', name: 'Guest' }
          ]}
        />
        <ReferenceInput label="Live Client Key" source="live_client_key" reference="clients" >
          <>
            <AutocompleteInput optionText="key" onChange={e => setUserKeyInput(e)} />
            <Confirm isOpen={isLiveKeyNullWarningOpen || isLiveKeySetWarningOpen}
              title={isLiveKeyNullWarningOpen ? 'Live Client Key is NOT set.' : (`Live Client Key has been set to ${userKeyInput}`)}
              content={isLiveKeyNullWarningOpen ? 'Are you sure you want to leave it blank? If yes, click confirm and resave.' : 'Please double-check to ensure this is correct. If yes, click confirm and resave.'}
              onConfirm={() => handleConfirmWarning(isLiveKeyNullWarningOpen ? 'null' : 'LiveClientKeyIsSet')}
              onClose={() => handleCancelWarning(isLiveKeyNullWarningOpen ? 'null' : 'LiveClientKeyIsSet')}
            />
          </>
        </ReferenceInput>
        <ReferenceInput label="client group key" source="client_group_key" reference="client_groups" >
          <AutocompleteInput optionText="key" suggestionLimit={10} />
        </ReferenceInput>
        <Confirm isOpen={isHubspotDealNullWarningOpen}
          title={'Hubspot Deal ID Not set'}
          content={'Are you sure you want to leave it blank? If yes, click confirm'}
          onConfirm={() => handleNullDealConfirmation()}
          onClose={() => handleNullDealCancel()}
        />
        <TextInput source="hubspot_company_identifier" label="Hubspot Company ID" validate={required()} />
        <TextInput source="hubspot_deal_identifier" label="Hubspot Deal ID" />
        <ReferenceInput label="Billing Account" source="billing_account" reference="billing_accounts" >
          <AutocompleteInput optionText="name" optionValue="external_id" suggestionLimit={10} validate={required()}></AutocompleteInput>
        </ReferenceInput>
        <ReferenceInput label="Guest Client" source="guest_client_key" reference="clients" >
          <AutocompleteInput optionText="name" optionValue="id"></AutocompleteInput>
        </ReferenceInput>
        <ArrayInput label="Backend User Invite Emails" source="invite_emails">
          <SimpleFormIterator>
            <TextInput source="email" label="Email" validate={required()} />
            <TextInput source="first_name" label="First Name" validate={required()} />
            <TextInput source="last_name" label="Last Name" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}
