import React from "react";
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '2em',
    paddingRight: '2em'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  subheading: {
    marginBottom: '0.5em',
    marginTop: '1.5em'
  },
  subgroup: {
    marginBottom: '1.5em',
  }
}));

const DocItem = (props) => {
  const classes = useStyles();

  var title = props.title || ''
  var body = props.children || ''

  return(
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {body}
          </Typography>
        </AccordionDetails>
      </Accordion>
  )
}

const DocGroup = (props) => {
  const classes = useStyles();

  var title = props.title || ''
  var children = props.children || ''
  var width = props.width || '35em'

  return(
    <div className={classes.root} style={{width: width}}>
      <h1>{title}</h1>
      {children}
    </div>
  )
}

const DocSubgroup = (props) => {
  const classes = useStyles();

  var title = props.title || ''
  var children = props.children || ''

  return(
    <div className={classes.subgroup}>
      <h4 className={classes.subheading}>{title}</h4>
      {children}
    </div>
  )
}

export {
  DocItem, 
  DocGroup,
  DocSubgroup
}