import React from "react";
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function CustomAccountContractCreateDoc() {
  const classes = useStyles();

  return (
    <Box sx={{ width: '50%', marginLeft: '2em' }}>
      <h2>More Info</h2>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Contract Structure Code</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This represents the arrangement of different products for a contract.</p>
            <ul>
              <li>For resellers, these are standard and consist of things like a per-user fee, per-month fee, and an app maintenance fee.</li>
              <li>If this is a reseller account, this field should be <i>exactly</i> like "RESELLER-2020".</li>
              <li>The code entered here must match a real contract structure code in the database.</li>
              <li>This <b>CANNOT</b> be altered later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Price Sheet Code</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This represents the list of prices per product that correspond to the products on the Contract Structure.</p>
            <ul>
              <li>These are standard for resellers, so the field must be <i>exactly</i> like "RESELLER-2020".</li>
              <li>The code entered here must match a real price sheet code in the database.</li>
              <li>This <b>CANNOT</b> be altered later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Valid From</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This is the first date on which the contract is valid.</p>
            <ul>
              <li>If this is a new reseller account, enter the first day of the month when the contract goes live, which is usually the current month as well.
              </li>
              <li>This <b>CANNOT</b> be altered later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Contract Anniversary</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This is the first day of the first month that the customer has service with us; it is is used to determine when to charge certain annual fees.</p>
            <ul>
              <li>Often the same as 'Valid From' for new accounts.</li>
              <li>This <b>CANNOT</b> be altered later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}