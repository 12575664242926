import React from "react";
import { Edit, SimpleForm, List, Datagrid, useGetOne, TextField, TextInput, Filter, Toolbar, 
        SaveButton, Create, required, Show, SimpleShowLayout, useRecordContext, usePermissions, regex
} from "react-admin";
import {Grid,Typography,Button,Box} from '@mui/material';
import ClientGroupCreateDoc from "../documentation/ClientGroupCreateDoc";
import { renderIfPermissions } from "../api/permissionManager";
import { Changelog } from "../components/Changelog";

const AssociatedClients = () => {
    const record = useRecordContext();
    const { data, isLoading, error } = useGetOne(
      'client_groups',
      {id: record.key}
    );
    if (isLoading) { return <p>Loading . . .</p> }
    if (error) { return <p>Error</p> }
    if(data.clients){
      return (
        <div>
          <Typography variant="subtitle1">
            Associated Clients:
          </Typography>
          <Grid container direction="column" alignItems="flex-start">
            {data.clients.map((client, index) => {
              return(
                <Grid item key={client.key}>
                  <Button variant="outlined" color="primary" href={`#/clients/${client.key}/show`} >{client.key}</Button>
                </Grid>
              );
            })}
          </Grid>
        </div>
    );
    }
    else{
      return(<div></div>)
    }
}

export const ClientGroupShow = () =>
{
  const { permissions } = usePermissions();
  return(
    <Show>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <SimpleShowLayout>
            <TextField source="name"/>
            <TextField source="key" label="Group Key"/>
            <AssociatedClients/>
          </SimpleShowLayout>
        </Grid>
        {renderIfPermissions(permissions, "clients", "write",
          <Grid item xs={5}>
            <SimpleShowLayout>
            <Changelog from="client_groups" />
            </SimpleShowLayout>
          </Grid> 
          )}
      </Grid>
    </Show>
);
}

export const ClientGroupFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search by name/key" source="name" alwaysOn />
  </Filter>
);

export const ClientGroupList = (props) => (
    <List {...props} pagination={false}  filters={<ClientGroupFilter/>} exporter={false}>
        <Datagrid key={props.key} rowClick="show" bulkActionButtons={false} size="medium">
            <TextField source="name" sortable={false}/>
            <TextField source="key" label="Group Key" sortable={false}/>
        </Datagrid>
    </List>
)

export const ClientGroupEditToolbar = () => (
    <Toolbar>
        <SaveButton/>
    </Toolbar>
);

export const ClientGroupEdit = () => {
  return (
    <Edit>
        <SimpleForm toolbar={<ClientGroupEditToolbar/>}>
            <TextInput source="name"/>
            <TextInput source="key" label="Group Key" disabled/>
        </SimpleForm>
    </Edit>
  )
};

const keyValidators = [required(), regex(/^[a-z]+(-[a-z]+)*$/, "The group key may only contain lowercase letters and hyphens, and it may not start or end with a hyphen."),];

const redirect = (data) => `/client_groups/${data.key}/show`;

export const ClientGroupCreate = () => {
  return (
    <Grid container direction={'row'}>
      <Create >
          <SimpleForm redirect={redirect} toolbar={<ClientGroupEditToolbar/>}>
              <TextInput source="name" validate={required()}/>
              <TextInput source="key" label="Group Key" validate={keyValidators}/>
          </SimpleForm>
      </Create>
      <Box px={4} py={2}>
        <ClientGroupCreateDoc/>
      </Box>
    </Grid>
  )
};
