import React from "react";
import {
  List, Datagrid, BooleanField, TextField, ReferenceField, SelectInput, NumberField, TextInput, Filter,
  DateField, ReferenceManyField, Show, Tab, TabbedShowLayout, useListContext,
  usePermissions, useGetOne, Labeled, useRecordContext, UrlField, Edit, SimpleForm,
  ReferenceInput, useRefresh, AutocompleteInput, useUpdate, Toolbar, SaveButton, Loading, Error,
  required, TopToolbar, EditButton, useShowController,
  FunctionField,
  ReferenceOneField,
  SimpleShowLayout,
  ArrayField,
  SingleFieldList
} from "react-admin";
import { renderIfPermissions } from "../api/permissionManager";
import { isEmpty } from "lodash";
import { useParams, useSearchParams } from 'react-router-dom';
import { Typography, Alert, Container } from "@mui/material";
import { UnsubscribeButton, CustomerUnsubscribeDraftsField } from "./customerUnsubscribeDrafts";
import { RotateKeyButton, CustomerAxleKeyRotationDraftsField } from "./customerAxleKeyRotationDrafts";
import { StatusField } from "./charges";

const CustomerFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Hub Customer Name" source="customer_name" alwaysOn />
    <TextInput label="Backend User Email" source="email" alwaysOn />

  </Filter>
);

export const DollarField = (props) => {
  const data = useRecordContext(props);
  const dollar_amount =
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(data.amount / 100);

  return (
    <Typography component="span" variant="body2">{dollar_amount}</Typography>
  );
};

const CustomerTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>Customer #{record.name}</span>;
};

const IntegrationMetadataFormatField = () => {
  const { id } = useParams()
  const {
    data, error, isLoading
  } = useGetOne("integration_metadata_formats", {
    id,
    meta: { account_type: "customer" },
  });

  if (error) return <></>;
  if (isLoading) return <></>;

  return (
    <div style={{ margin: "0", marginTop: "8px" }}>
      <Typography
        variant="body1"
        style={{ fontSize: "0.75em", marginBottom: "0.2em", color: "rgba(0, 0, 0, 0.6" }}
      >
        Integration Metadata Format
      </Typography>
      <Typography variant="body2">
        {data.description}
      </Typography>
    </div>
  );
};

const CustomerShowActions = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions()
  return (
    <TopToolbar>
      {renderIfPermissions(permissions, 'documents', "write", <EditButton />)}
      {record != null && record.customer_status === 'Subscribed' &&  <UnsubscribeButton />}
      {record != null && (renderIfPermissions(permissions, 'customers', "write", <RotateKeyButton name={record.name} />))}
    </TopToolbar>
  );
};

export const CustomerShow = (props) => {
  const { permissions } = usePermissions()
  const customer_uuid = useParams().id;
  const { record } = useShowController();
  const [searchParams] = useSearchParams();

  return (
    <>
      {searchParams.get("rotation_draft_created") === "" &&
        <div style={{ "paddingTop?": "10px" }}>
          <Alert severity="warning">
            Drive Axle key rotation will NOT take effect until approval by a second administrator
          </Alert>
        </div>
      }
      {searchParams.get("draft_created") === "" &&
        <div style={{ "paddingTop?": "10px" }}>
          <Alert severity="warning">
            Customer unsubscribe will NOT take effect until approval by a second administrator
          </Alert>
        </div>}
      <Show title={<CustomerTitle />} actions={renderIfPermissions(permissions, 'customers', "write", <CustomerShowActions />)} {...props}>
        <TabbedShowLayout syncWithLocation={false} spacing={2}>
          <Tab label="Customer Info">
            <TextField source="name" />
            <TextField source="account_status" />
            <DateField source="created_at" />
            <TextField source="customer_status" />
            <TextField source="product_description" />
            <TextField source="product_name" />
            <TextField source="product_price" />
            <TextField source="stripe_customer_id" />
            <BooleanField source="rescans_enabled" />
            {renderIfPermissions(permissions, "documents", "read_only",
              <ReferenceField label="Black & White Format" source="document_format_id" reference="document_types" >
                <TextField source="name" />
              </ReferenceField>
            )}
            {renderIfPermissions(permissions, "documents", "read_only",
              <ReferenceField label="Full Color Format" source="original_document_format_id" reference="document_types" >
                <TextField source="name" />
              </ReferenceField>
            )}
            <NumberField source="max_bad_quality" />
            <NumberField source="min_good_quality" />
            <TextField source="uuid" />
            <TextField source="last_4_card_digits" />
            <IntegrationMetadataFormatField />
          </Tab>
          {renderIfPermissions(permissions, "backend_users", "read_only",
            <Tab label="Backend Users">
              <ReferenceManyField label="Backend Users" reference="backend_users" target="uuid">
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="first_name" />
                  <TextField source="last_name" />
                  <TextField source="email" />
                  <TextField source="confirmed_at" />
                  <TextField source="invitation_sent_at" />
                  <TextField source="active" />
                  <TextField source="allow_ftp_downloads" />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            )}
            {renderIfPermissions(permissions, 'customers', "write",
              <Tab label="Charges">
                <ReferenceManyField label="Charges" reference="stripe_charges" target={customer_uuid}>
                  <Datagrid
                    rowClick={(id, resource, record) => `/stripe_charges/${record.id}`}
                    bulkActionButtons={false}
                  >                      
                    <DateField source="created" showTime/>
                    <DollarField label="Amount" {...props}/>
                    <TextField source="description"/>
                    <FunctionField
                      label="Status"
                      render={(record) => (
                        <StatusField status={record.status} />
                      )}
                    />
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
            )}
          {record != null && (
            renderIfPermissions(permissions, "customers", "read_only",
              <Tab label="Unsubscribe drafts">
                <CustomerUnsubscribeDraftsField />
              </Tab>)
          )}
          {renderIfPermissions(permissions, "customers", "write",
            <Tab label="Rotation drafts">
              <CustomerAxleKeyRotationDraftsField />
            </Tab>
          )}
        </TabbedShowLayout>
      </Show>
    </>
  );
}

const CustomCustomerList = (props) => {
  const { filterValues } = useListContext(props);
  if (isEmpty(filterValues)) {
    return (
      <h2 align='center' style={{ margin: 35, padding: 10 }}>Enter a name or email to view matching customers.</h2>
    )
  }
  return (
    <Datagrid key={props.key} rowClick="show" bulkActionButtons={false} size="medium">
      <TextField source="name" />
      <TextField source="account_status" />
      <DateField source="created_at" />
    </Datagrid>
  );
}

export const CustomerList = (props) => (
  <List {...props} pagination={false} filters={<CustomerFilter />} exporter={false}>
    <CustomCustomerList {...props} />
  </List>
)

const difference = (oldObj, newObj) => {
  let diff = "";
  Object.keys(oldObj).forEach(key => {
    if (typeof oldObj[key] === 'object') {
      if (JSON.stringify(oldObj[key]) !== JSON.stringify(newObj[key])) {
        diff = diff.concat(`${key}:`.padEnd(30) + `${JSON.stringify(oldObj[key])} -> ${JSON.stringify(newObj[key])}\n`.padStart(30))
      }
    }
    else if (oldObj[key] !== newObj[key]) {
      diff = diff.concat(`${key}: `.padEnd(30) + `${oldObj[key]} -> ${newObj[key]}\n`.padStart(30))
    }
  });
  return diff;
};

const metadifference = (oldObj, newObj) => {
  let diff = "";
  diff = diff.concat(`MetadataFormat:`.padEnd(30) + `${JSON.stringify(oldObj)} -> ${JSON.stringify(newObj)}\n`.padStart(30));
  return diff;
}

const CustomerEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
};

export const CustomerEdit = () => {
  const { id } = useParams();
  const [updateMetadataFormat] = useUpdate();
  const [updateDocumentID] = useUpdate();

  const {
    data: documentData,
    error: documentError,
  } = useGetOne('customers', { id });

  const {
    data: metadataFormatData,
    error: metadataFormatError,
    isFetching: metadataFormatIsFetching,
  } = useGetOne("integration_metadata_formats", {
    id,
    meta: { account_type: "customer" },
  });

  if (documentError || metadataFormatError) {
    useRefresh;
  }

  else if (!metadataFormatData) {
    return null;
  }

  else if (metadataFormatIsFetching) {
    return <Loading />;
  }

  const emptyStringsToNull = data => {
    let sanitizedData = data
    Object.keys(data).forEach(key => {
      if (typeof data[key] === "string" && data[key] === "") data[key] = null
      if (typeof data[key] === "object" && data[key]) emptyStringsToNull(data[key])
    })
    return sanitizedData
  }

  const transform = (data) => {
    const newData = emptyStringsToNull(data);
    const docdiff = difference(documentData, newData);

    if (metadataFormatData.name != data.integration_metadata_format) {
      const metadiff = metadifference(metadataFormatData.name, newData.integration_metadata_format);
      if (confirm(`Please ensure these changes are correct:\n\n${metadiff}`)) {
        updateMetadataFormat("integration_metadata_formats", {
          id: id,
          data: {
            integration_metadata_format: data.integration_metadata_format,
            account_type: "customer",
          },
          previousData: {},
        });
      }
    }
    if ((documentData.original_document_format_id != newData.original_document_format_id) || (documentData.document_format_id != newData.document_format_id)) {
      if (confirm(`Please ensure these changes are correct:\n\n${docdiff}`)) {
        updateDocumentID("customers", {
          id: id,
          data: {
            original_document_format_id: newData.original_document_format_id,
            document_format_id: newData.document_format_id
          },
          previousData: {},
        });
      }
    }
  };

  return (
    <Edit transform={(data) => transform(data)} redirect="show" mutationMode="pessimistic">
      <SimpleForm label="Customer Configuration" toolbar={<CustomerEditToolbar />} redirect="show" sanitizeemptyvalues="true">
        <ReferenceInput
          source="integration_metadata_format"
          reference="integration_metadata_formats"
        >
          <AutocompleteInput
            source="description"
            label="Integration metadata format"
            defaultValue={metadataFormatData.name}
            validate={required()}
            optionText="description"
          />
        </ReferenceInput>
        <ReferenceInput label="Black & White Format" source="document_format_id" reference="document_types" >
          <SelectInput optionText="name" validate={required()} />
        </ReferenceInput>
        <ReferenceInput label="Full Color Format" source="original_document_format_id" reference="document_types" >
          <SelectInput optionText="name" validate={required()} disableValue="supports_full_color" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};