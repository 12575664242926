import React from "react";
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export function ProductCreateDoc() {
  const classes = useStyles();

  return (
    <Box sx={{ width: '50%', marginLeft: '2em' }}>
      <h2>More Info</h2>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Product Code</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This is the code used to identify this product.</p>
            <ul>
              <li>This <b>CANNOT</b> be altered later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Product Name</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This is the actual name of the product.</p>
            <ul>
              <li>This <b>CANNOT</b> be altered later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Description</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This is the description of the product.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Application Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>Application filters are just boolean expressions encoded in JSON</p>
            <p>Currently, we only check for <u>valid JSON</u>. We do <b>NOT</b> check for correct fields and values.</p>
            <ul>
              <li>Consists of predicates and clauses.</li>
              <li>If you define multiple value fields, then the invoice generator will look first for <b>number_value</b>, then <b>boolean_value</b> and then <b>string_value</b>.</li>
              <li>Never define a value field with a null value; this is not supported, and will cause invoice generator to error out.</li>
              <li>If the operator is <b>IS_NULL</b>, then any value fields will be ignored.</li>
              <ul>
                <li><b>property</b> - column name from the source query, the possible values here depend on the billing unit.</li>
                <li><b>number_value</b> - A number value, can be either a JSON integer or float value. If it's a float value, the invoice generator will round it to 4 decimal places.</li>
                <li><b>boolean_value</b> - A JSON boolean value. Can be either true or false</li>
                <li><b>string_value</b> - A string value</li>
                <li>
                    <b>operator:</b>
                    <ul>
                        <li><b>EQUALS</b> - If no operator is defined, this is the default value.</li>
                        <li><b>NOT_EQUALS</b></li>
                        <li><b>LESS_THAN</b> - If the property is numerically less than the value. Use only in conjunction with number_value.</li>
                        <li><b>GREATER_THAN</b> - If the property is numerically greater than the value. Use only in conjunction with number_value.</li>
                        <li><b>IS_NULL</b> - If used, a value field is not required and if it is provided, it will be ignored.</li>
                    </ul>
                </li>
              </ul>
              <li>Clauses (including the top-level application filter object) can have an optional junction_type defined. The default value is <b>AND</b>, but you can also define it to be <b>OR</b>.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Billing Unit</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>Billing unit to be used for this product. </p>
            <ul>
                <li>There are six different billing units</li>
                <li>
                    <li><b>flat_fee</b> - This is always just a flat fee, and can either be annual or monthly depending on the value of billing_period. It is always assessed, and is not contingent. The application_filters column is ignored for flat_fee products.</li>
                    <li><b>per_user</b> - This means the product operates off of a user count, and the application_filters column is a selector for determining which users should be included as having the product.</li>
                    <li><b>per_load</b> - This means the product operates off of a load count, and the application_filters column is a selector for determining which loads should be included as having the product.</li>
                    <li><b>per_usage_count</b> - This means the invoice generator just looks up the unit count.</li>
                    <li>
                        <b>per_api_call</b> - This means the product only works for API calls that log an analytic event named limited_api_request. 
                        <ul>
                            <li>If you are creating a product for this, make sure you select only the api_name you want or else you could end up counting every limited_api_request which is likely not what you want.</li> 
                        </ul>                         
                    </li>
                    <li><b>per_app</b> - This means the product is assessed per-app.</li>
                </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Billing Period</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This is the how often this product is billed.</p>
            <ul>
                <li>Either monthly or annually.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}