import React, { useState } from "react";
import PublishIcon from '@mui/icons-material/Publish';
import { useRefresh } from "react-admin";

export const AppAssetUploadComponent = (props) => {
  const [fileReader] = useState(new FileReader());
  const clientKey = props.id;
  const token = localStorage.getItem("token");
  const API_URL = (window.frameElement && window.frameElement.getAttribute("data-api-url")) ||
    (process && process.env && process.env.REACT_APP_API_URL);
  const refresh = useRefresh();
  var appAssetName = props.appAssetName;
  var file;

  const presignedURL = async () => {
    var requestOptions = {
      method: 'PUT',
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json', "eleos-admin-client-key": clientKey }
    };

    const response = await fetch(`${API_URL}/internal/clients/${clientKey}/assets/${appAssetName}`, requestOptions);
    if (response.ok) {
      var body = await response.json();
    }
    return body.url
  }

  const handleFileUpload = (event) => {
    file = event.target.files[0];
    fileReader.onloadend = handleFileRead;
    fileReader.readAsArrayBuffer(file);
  }

  const checkIconReqs = (width, height) => {
    if (height !== width) {
      alert(`Invalid dimensions! App Icon dimensions is not square. Uploaded image is ${width}x${height}`);
      return false;
    } else if (width > 1800) {
      alert(`Invalid dimensions! App Icon dimensions are too large. Uploaded image is ${width}x${height}. Resize image.`);
      return false;
    }
    return true;
  }

  const checkAlphaChannel = (img) => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    for (let i = 3; i < data.length; i += 4) {
      if (data[i] !== 255) {
        return true;
      }
    }
    return false;
  };

  const checkLogoReqs = (width, height, img) => {
    if (height >= width) {
      alert(`Invalid dimensions! Logo dimensions is not rectangular. Uploaded image is ${width}x${height}`);
      return false;
    } else if (width > 1800) {
      alert(`Invalid dimensions! Logo dimensions are too large. Uploaded image is ${width}x${height}. Resize image.`);
      return false;
    } else if (!checkAlphaChannel(img)) {
      alert('Invalid image! Logo should have an alpha channel.');
      return false;
    }
    return true;
  };

  const handleFileRead = async (event) => {
    var data = fileReader.result;
  
    if (file.type !== 'image/png') {
      alert("Invalid file type! Please upload a png.");
      return;
    }
  
    // Create an Image element to get the dimensions
    const img = new Image();
    img.src = URL.createObjectURL(file);
  
    const handleDimensions = async () => {
      return new Promise((resolve, reject) => {
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          if (appAssetName === "app-icon") {
            if (!checkIconReqs(width, height)) {
              reject();
            }
          } else if (appAssetName === "dashboard-logo" || appAssetName === "login-logo") {
            if (!checkLogoReqs(width, height, img)) {
              reject();
            }
          }
  
          resolve(); // Resolve the promise when dimensions are valid
        };
  
        img.onerror = () => {
          alert("Error loading the image.");
          reject(); // Reject the promise in case of an error
        };
      });
    };
  
    try {
      await handleDimensions(); // Wait for the dimensions to be checked
      const url = await presignedURL(); // Wait for the presigned URL
      var requestOptions = {
        method: 'PUT',
        body: data
      };
      const response = await fetch(url, requestOptions); // Wait for the fetch request
  
      if (response.ok) {
        alert(`App asset uploaded successfully`);
        refresh();
      } else {
        const data = await response.json();
        var errorString = "\n";
        for (var error in data) {
          const errorObj = data[error];
          errorString = errorString + "> " + errorObj.description + "\n";
        }
        alert(`App asset failed to upload: ${errorString}`);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const buttonStyle = {
    display: "inline-block",
    margin: "0 0.3em 0.3em 0",
    borderRadius: "2em",
    boxSizing: "border-box",
    textDecoration: "none",
    fontFamily: 'Roboto',
    fontWeight: "300",
    color: "#FFFFFF",
    backgroundColor: "#4eb5f1",
    textAlign: "center",
    cursor: "pointer"
  }

  const uploadBtn = <button style={buttonStyle}><label htmlFor="inputfile" style={{ cursor: "pointer" }}><PublishIcon /></label></button>

  return (
    <div id="FileHandler">
      <input type="file" name="file" className="inputfile" id="inputfile" onChange={(e) => { handleFileUpload(e); e.target.value = null }} hidden />
      <h4>Upload {uploadBtn}</h4>
    </div>
  );

}
