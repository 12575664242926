import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Alert, Button, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { Cancel, CheckCircleOutline, HighlightOffOutlined, Info, ModeEdit, Save, Send } from '@mui/icons-material';
import dataProvider from "../api/DataProvider";

const formatDate = (datetime) => {
    let date = new Date(datetime).toLocaleString('en-US', {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false
    }
    )
    const formattedDate = date + ' GMT'
    return formattedDate
}


const useStyles = makeStyles(() =>
    createStyles({
        highlightedCell: {
            animation: "$fadeIn 1.5s ease-in-out"
        },
        "@keyframes fadeIn": {
            "0%": {
                background: '#baf7ba',
                opacity: 0.25,
                transform: "translateX(-5rem)"
            },
            "25%": {
                background: '#baf7ba',
                opacity: 0.5,
                transform: "translateX(0)"
            },
            "50%": {
                background: '#baf7ba',
                opacity: 0.75,
                transform: "translateX(0)"
            },
            "75%": {
                background: '#baf7ba',
                opacity: 1,
                transform: "translateX(0)"
            },
            "100%": {
                opacity: 1,
                transform: "translateX(0)"
            }
        },  
        shakeIcon: {
            animation: '$fade 0.3s linear, $shakeAnimation 0.5s',
        },
        '@keyframes shakeAnimation': {
            '0%': { transform: 'rotate(0deg)' },
            '50%': { transform: 'rotate(-90deg)' },
            '100%': { transform: 'rotate(0deg)' },
        },
        fadeIcon: {
            animation: "$fade 0.5s"
        },
        "@keyframes fade": {
            "from": {
                opacity: "0%"
            },
            "to": {
                opacity: "100%"
            }
        },
        flyIcon: {
            animation: '$flyAnimation 0.7s',
        },
        "@keyframes flyAnimation": {
            '0%': { transform: 'translateX(0)' },
            '100%': { transform: 'translateX(100%)', opacity: 0 },
        }
    })
);

export const ShowDocTypes = (props) => {
    const [types, setTypes] = useState([]);
    const [formats, setFormats] = useState([]);
    const [editing, setEditing] = useState(false);
    const [editingRow, setEditingRow] = useState(null);
    const [highlight, setHighlight] = useState(null);
    const [alert, setAlert] = useState({
        "message": "",
        "success": false,
        "error": false
    })
    const classes = useStyles();
    const name = props.record.name;
    const key = props.record.key;
    const [hover, setHover] = useState(false);
    const [saveClick, setSaveClick] = useState(false);
    const [info, setInfo] = useState(false);

    const infoDesc =
        <Typography fontSize={'12px'}>This table displays a list of the client's document types and their customized formats. These formats are customized internally and are usually different than the overall integration format set in App Manager.
            <span>
                <Typography fontSize={'12px'}>In the past, we have had clients request us to create these special formats for them. We no longer create these for clients, but we will still support updating the current existing ones on our end.</Typography>
            </span>
            <span>
                <Typography fontSize={'10px'}>Note: This is not a list of all of the client's document types.</Typography>
            </span>
        </Typography>

    const fetchFormats = async () => {
        let formats = await dataProvider('GET_MANY', 'document_types', {}, `Bearer ${localStorage.getItem('token')}`)
        setFormats(formats.data);
    };

    const fetchDocTypes = async () => {
        let types = await dataProvider('GET_LIST', 'document_type_format_associations', { id: key }, `Bearer ${localStorage.getItem('token')}`)
        setTypes(types.data);
        console.log(types)
    };

    useEffect(() => {
        fetchDocTypes();
        fetchFormats();
    }, []);

    const handleEditButton = () => {
        setEditing(true);
        setHighlight(null);
        setHover(false);
        setAlert({
            "message": "",
            "success": false,
            "error": false
        })
    }

    const handleCancelButton = async () => {
        await fetchDocTypes();
        setEditing(false);
        setHighlight(null);
        setHover(false);
        setSaveClick(false);
        setHover(false);
        setAlert({
            "message": "",
            "success": false,
            "error": false
        })
        setEditingRow(null);
    }

    const handleFormatChange = (e, index) => {
        const { value } = e.target;
        const newId = parseInt(value, 10);
        setTypes((prevTypes) =>
            prevTypes.map((prevType, i) =>
                i === index ? { ...prevType, new_document_format_id: newId } : prevType
            )
        );
        console.log("INDEX IS: ", index)
        setEditingRow(index);
    };

    const handleSaveButton = async () => {
        setHover(false);
        let update = types.some(type => type.new_document_format_id);
        if (!update) {
            setEditing(false);
            setSaveClick(false);
            setHover(false);
            setEditingRow(null);
            return;
        }
        const editedType = types.find((type, index) => index === editingRow);
        const body = {
            "document_type_id": editedType.document_type_id,
            "new_document_format_id": editedType.new_document_format_id
        };
        try {
            await dataProvider('UPDATE', 'document_type_format_associations', { id: key, body: JSON.stringify(body) }, `Bearer ${localStorage.getItem('token')}`);
            await fetchDocTypes();
            setEditing(false);
            setAlert({
                "message": "Successfully updated document format associations.",
                "success": true,
                "error": false
            });
        } catch (error) {
            console.error('Error:', JSON.stringify(error));
            setAlert({
                "message": `Failed to update document format associations: ${error.message}`,
                "success": false,
                "error": true
            });
        } finally {
            setSaveClick(false);
            setHover(false);
            setEditingRow(null);
            setHighlight(editingRow);
        }
    };

    return (
        <div style={{ marginTop: '2em' }}>
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ alignSelf: 'center' }}>
                        <Typography style={{ marginLeft: '2em', marginBottom: '2em' }}>
                            <h3>
                                <Tooltip arrow placement='top' title={infoDesc}>
                                    <Info onMouseEnter={() => { setInfo(true) }} onMouseLeave={() => { setInfo(false) }} style={{ fontSize: 'small', marginRight: '0.3em', marginBottom: '0.6em' }} />
                                </Tooltip>
                                {`${name}'s Custom Document Type Format Associations:`}
                            </h3>
                        </Typography>
                        {/* Show either success or error message after attempting to update */}
                        {alert.message && (<Alert severity={(alert.success) ? "success" : "error"}>{alert.message}</Alert>)}
                    </div>
                    {/* When NOT editing, display the Edit button */}
                    {(!editing && types.length > 0) && (
                        <div style={{ alignSelf: 'center' }}>
                            <Button onMouseEnter={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }} variant='contained' onClick={handleEditButton}>
                                {hover && (
                                    <ModeEdit style={{ marginRight: '0.5em', fontSize: '18px' }} className={classes.shakeIcon} />
                                )}
                                <Typography>Edit</Typography>
                            </Button>
                        </div>
                    )}
                </div>
                {/* Only show the table if the client has any document type format associations. */}
                {types.length > 0 ? (
                    <div>
                        <Table component={Paper}>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Created At</b></TableCell>
                                    <TableCell><b>Document Type</b></TableCell>
                                    <TableCell style={{ textAlign: 'center' }}><b>Photograph/Color Doc Type?</b></TableCell>
                                    {editing ? (<TableCell style={{ textAlign: 'center' }}><b>Format</b><span><Typography style={{ fontSize: 'smaller' }}>(You may only edit one format at a time.)</Typography></span></TableCell>) : (<TableCell><b>Format Association</b></TableCell>)}
                                    <TableCell><b>Updated At</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {types.map((type, index) => {
                                    {/* Only show appropriate formats for color document types */ }
                                    const compatibleFormats = formats.filter(format => {
                                        return !(type.photograph && !format.supports_full_color);
                                    });
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(type.created_at)}</TableCell>
                                            <TableCell>{type.document_type_name}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                {type.photograph ? <CheckCircleOutline color='success' /> : <HighlightOffOutlined color='error' />}
                                            </TableCell>
                                            <TableCell style={{textAlign:'center'}} className={highlight === index ? classes.highlightedCell : ''}>
                                                {/* When editing, show selects with filtered formats based on the document type. Else, just display the associated format name. */}
                                                {editing ? (
                                                    <select
                                                        className={`format-select-${index}`}
                                                        style={{ width: '10em' }}
                                                        value={type.new_document_format_id || type.document_format_id}
                                                        onChange={(e) => handleFormatChange(e, index)}
                                                        disabled={editingRow !== null && editingRow !== index}
                                                    >
                                                        {compatibleFormats.map((format, i) => (
                                                            <option key={i} value={format.id}>
                                                                {format.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    type.document_format_name
                                                )}
                                            </TableCell>
                                            <TableCell>{formatDate(type.updated_at)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {/* Save and Cancel buttons when editing */}
                        {editing && (
                            <Stack style={{ marginTop: '1em' }} spacing={2} direction='row'>
                                <Button
                                    onMouseEnter={() => { setHover(true) }}
                                    onMouseLeave={() => { setHover(false) }}
                                    onMouseDownCapture={() => { setSaveClick(true) }}
                                    variant="contained"
                                    onClick={handleSaveButton}
                                    endIcon={
                                        (!saveClick && !hover) ? (<Save style={{ marginLeft: '0.5em' }} />)
                                            :
                                            (
                                                (!saveClick && hover) ? (<Send className={classes.fadeIcon} style={{ marginLeft: '0.5em' }} />)
                                                    :
                                                    (<>{saveClick && (<Send style={{ marginLeft: '0.5em' }} className={classes.flyIcon} />)}</>)
                                            )
                                    }
                                >
                                    Save
                                </Button>
                                <Button variant="contained" color="error" onClick={handleCancelButton} endIcon={<Cancel />}>
                                    Cancel
                                </Button>
                            </Stack>
                        )}
                    </div>
                ) : (
                    <div>
                        <Typography>This client does not have any document type format associations.</Typography>
                    </div>
                )}
            </div>
        </div>
    );
}