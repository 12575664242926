import React from "react";
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function ClientGroupCreateDoc() {
  const classes = useStyles();

  return(
    <div className={classes.root}>
      <h1>More Info</h1>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Typography className={classes.heading}>Name</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>The client name is the customer’s company name</p>
            <ul>
              <li>This can be altered later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Typography className={classes.heading}>Group Key</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>The key should resemble the customer</p>
            <ul>
              <li>This <b>CANNOT</b> be altered later</li>
              <li>No spaces, only hyphens</li>
              <li>No numbers, all lowercase</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}