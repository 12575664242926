import { useLogin } from "react-admin";
import { Button } from '@mui/material';
import { withStyles, createStyles } from "@mui/styles";
import classnames from "classnames";
import Card from "@mui/material/Card";
import { useEffect } from 'react'

const styles = (theme) =>
  createStyles({
    main: {
      backgroundColor: "#505759",
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      height: "1px",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    card: {
      minWidth: 300,
      minHeight: 200,
      marginTop: "6em",
    },
    logo: {
      margin: "2em",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      backgroundColor: "#e96719",
      padding: "0.5em",
    },
    button: {
      margin: "2em auto",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }
  });

var client;

const loadGsiScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  })

const ReactGoogleLogin = ({ theme, classes, className }) => {
  const login = useLogin();

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'
    const id = process.env.REACT_APP_GSUITE_CLIENT_ID

    loadGsiScript(src)
      .then(() => {
        /*global google*/
        client = google.accounts.oauth2.initTokenClient({
          client_id: id,
          scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/admin.directory.user.readonly",
          callback: (tokenResponse) => {
            login({'access_token': tokenResponse.access_token})
          },
        });
      })
      .catch(console.error)

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, [])

  return (
    <div className={classnames(classes.main, className)}>
      <Card className={classes.card}>
        <div className={classes.logo}>
          <img src="/IA_Logo.png" height="80" alt=""/>
        </div>
        <Button className={classes.button} variant="outlined" onClick={() => client.requestAccessToken()}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/></svg>
          &nbsp; Sign in with Google
        </Button>
      </Card>
    </div>
  )
}

const StyledReactLoginPage = withStyles(styles)(ReactGoogleLogin);

export default StyledReactLoginPage 