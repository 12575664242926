import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState} from "react";
import {Button as MaterialButton} from '@mui/material';
import { useNotify, TextInput, useRefresh, SimpleForm, CheckboxGroupInput, useCreate, useGetList } from 'react-admin';

export const CreatePermissions = (props) => {
  const [checkboxFields, setCheckBoxFields] = useState([]);
  const [open, setOpen] = useState(false);
  const [create] = useCreate();
  const { data, error } = useGetList('internal_permissions', {meta: 'getResources'});
  const notify = useNotify();
  const refresh = useRefresh();

  const resources = async () => {
    var resources = []
    if(data){
      for(var i = 0; i < data.length; i++){
        resources.push(data[i].value)
      }
      setCheckBoxFields(resources)
    }
    if(error) {
      notify(`Error retrieving IA resources: \n ${JSON.stringify(error)}`, { type: 'error' })
      console.error(error)
    }
  };

  const createGroup = async(data) => {
    await create('internal_permissions', {meta: 'createGroup', data: {group: data.group}}, {
      onSuccess: () => {
        notify(`${data.group} has been successfully created`)
      }, 
      onError: (error) => {
        notify(`Failed to create group: ${data.group}`, {type: 'error'});
        console.error(`Failed to create group: ${error}`)
      }
    })
  }

  const createNewPermissions = async (data) => {
    for(var i = 0; i < checkboxFields.length; i++){
      let requested_perms = data[checkboxFields[i]]
      let resource = checkboxFields[i]
      let new_read = false;
      let new_write = false;
      let new_active = false
      if(requested_perms != undefined){
        new_write = data[checkboxFields[i]].includes('write')
        new_read = data[checkboxFields[i]].includes('read') || new_write
        new_active = data[checkboxFields[i]].includes('is_active')
      }
      create('internal_permissions', {data: {group: data.group, resource: resource, read: new_read, write: new_write, is_active: new_active}}, {
        onSuccess: () => {
          notify(`Permissions created for ${data.group}`)
        }, 
        onError: (error) => {
          notify(`Failed to add permissions check console for details`, {type: 'error'});
          console.error(`Failed to add ${resource} read: ${new_read}, write: ${new_write}, and active: ${new_active} permissions for ${data.group}: ${error}`)
        }
      })
    }
  }

  const handleOpen = () => {
    setOpen(true);
    resources();
  }

  const handleSubmit = async (data) => {
    await createGroup(data);
    await createNewPermissions(data)
    setOpen(false);
    refresh();
  }
  
  const emailCheck = (email) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email) ? undefined : 'Invalid Email Format';
  }
   
  const permissions_options = [
      {id: 'is_active', name: 'Active'},
      {id: 'read', name: 'Read'},
      {id: 'write', name: 'Write'}
  ]
  return(
    <>
    <MaterialButton variant="contained" color="primary" onClick={() =>{handleOpen()}} sx={{marginBottom: '10px'}}>
      Create New Group
    </MaterialButton>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="create-permissions" fullWidth maxWidth='s'>
        <DialogTitle id="add-permissions-dialog">Create New Group</DialogTitle>
        <DialogContent>
            <SimpleForm onSubmit={handleSubmit} redirect={false} {...props}>
              <TextInput source="group" label="Google Group Email" sx={{height: 40}} required={true} validate={emailCheck} />
              { checkboxFields.map((field, index) => (
              <CheckboxGroupInput key={index} source={`${field}`} choices={permissions_options} label={field} sx={{height: '40px'}}/> ))}
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  )
};