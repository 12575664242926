import React from "react";
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export function ContractStructureCreateDoc() {
  const classes = useStyles();

  return (
    <Box sx={{ width: '50%', marginLeft: '2em' }}>
      <h2>More Info</h2>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Contract Structure Code</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This code will be what is referenced in the account contract and represent the arrangement of different products.</p>
            <ul>
              <li>This <b>CANNOT</b> be altered later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Contract Structure Name</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This is the actual name of the contract structure</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export function ContractStructureProductCreateDoc() {
  const classes = useStyles();

  return (
    <Box sx={{ width: '50%', marginLeft: '2em' }}>
      <h2>More Info</h2>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Contract Structure Code</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This is the code of the contract structure this contract structure product will belong to.</p>
            <ul>
              <li>This <b>CANNOT</b> be altered later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Product Code</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This is the product code for the product to be used.</p>
            <ul>
              <li>The code entered here must match a real product code in the database.</li>
              <li>This <b>CANNOT</b> be altered later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}