import React from "react";
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import redWarningInMessageDocumentQualityError from '../resources/img/red-warning.jpg'
import grayWarningInMessageDocumentQualityError from '../resources/img/gray-warning.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75%',
    marginLeft: '3rem'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function ClientCreateDoc() {
  const classes = useStyles();

  return(
    <div className={classes.root}>
      <h1>More Info</h1>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Typography className={classes.heading}>Client Name</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>The client name is the customer’s company name</p>
            <ul>
              <li>This can be found from the environment request form</li>
              <li>This can be altered later</li>
              <li>If this is for a sandbox or guest environment, add “ (dev)” or " (guest)" respectively to the ending.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Typography className={classes.heading}>Client Key</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>The client key should resemble the customer</p>
            <ul>
              <li>This <b>CANNOT</b> be altered later</li>
              <li>No spaces, only hyphens</li>
              <li>No numbers, all lowercase</li>
              <li>Can't start with a number</li>
              <li>If this is for a sandbox or guest environment, add “-dev” or "-guest" respectively to the ending</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Typography className={classes.heading}>Client Type</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>The type of environment being created</p>
            <ul>
              <li>For types Guest and Sandbox, you must append the proper suffixes to the name and client key</li>
              <li>No suffixes are needed for a Live environment</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Typography className={classes.heading}>Live Client Key</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This is the primary environment the client is tied to.</p>
            <ul>
              <li>If the client does <b>not</b> have an associated app, this will need to be set for a user's load updates and push notifications to function properly.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Typography className={classes.heading}>Client Group Key</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This key is defined when the client group is created.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Typography className={classes.heading}>Hubspot Company ID</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>The ID of the corresponding Hubspot company.</p>
            <ul>
              <li>To find the ID, look up the name of the customer in this <a href="https://app.periscopedata.com/app/eleostech/595359/Platform-Support?widget=18319420&udv=0" target="_blank">report</a> </li>
              <li>If you cannot find the appropriate company in the report, reach out to the company's account executive</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Typography className={classes.heading}>Hubspot Deal ID</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>The ID of the corresponding Hubspot deal.</p>
            <ul>
              <li>This field should almost always be filled, only in rare circumstances should this be left empty</li>
              <li>To find the ID, look up the name of the deal/customer in this <a href="https://app.periscopedata.com/app/eleostech/595359/Platform-Support?widget=18319610&udv=0" target="_blank">report</a></li>
              <li>If you cannot find the appropriate deal in the report, reach out to the company's account executive</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
        <Typography className={classes.heading}>Billing Account</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>The UUID is the billing account number that is associated with the relevant reseller/house account</p>
            <ul>
              <li>If the appropriate billing account does not appear in the list, let Ryan Crum know</li>
              <li>The UUID list can be found in Periscope</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Typography className={classes.heading}>Guest Client</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>This is the environment that a user enters when selecting guest mode at login.</p>
            <ul>
              <li>If naming conventions were followed properly, the guest client should have the same name as this client but with "(guest)" at the end.</li>
              <li>The guest client must be in the same client group as this client.</li>
              <li>The associated guest client must be of type "guest"</li>
              <li>A client cannot be its own guest client.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Typography className={classes.heading}>Backend User Invite Emails</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>Emails to send invites to be Backend Users for this client.</p>
            <ul>
              <li>Should only be set when creating a <b>fresh customer, not for another reseller environment</b></li>
              <li>These invites gives these backend users full admin permissions, including ROAMING permissions (can switch between environments in the Hub)</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
