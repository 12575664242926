const permissionManager = () => {
    const getPerms = () => {
      // Retrieve granular permissions if available
      const granularPermissions = localStorage.getItem('granular_permissions');
      if (granularPermissions) {
          return new Map(Object.entries(JSON.parse(granularPermissions)));
      }
      // Fallback to old permissions format
      return {
          platform: localStorage.getItem('permissions'),
          billing: localStorage.getItem('billing_permissions'),
          permissions_management: localStorage.getItem('permissions_management')
      };
    }
    const setPerms = (perms) => {
      localStorage.setItem('permissions', perms);
      return true;
    }

    const setBillingPerms = (perms) => {
      localStorage.setItem('billing_permissions', perms);
      return true;
    }

    const setPermissionsPerms = (perms) => {
      localStorage.setItem('permissions_management', perms);
      return true
    }
    const deletePerms = () => {
      localStorage.removeItem('permissions');
      localStorage.removeItem('billing_permissions');
      localStorage.removeItem('granular_permissions');
      localStorage.removeItem('permissions_management')
      return true;
    }
    return {
      getPerms,
      setPerms,
      setBillingPerms,
      setPermissionsPerms,
      deletePerms
    }
  };

export const renderIfOldPermissions = (permissions, requiredPermission, toRender) => {
  if(permissions !== undefined) {
    if(permissions.platform === 'full') {
      return toRender
    }
    else if(permissions.platform === 'read_only' && requiredPermission === 'read_only') {
      return toRender
    }
  }
  return null
}

export const renderIfBillingPermissions = (permissions, toRender) => {
  if(permissions !== undefined) {
    if(permissions.billing === 'full') {
      return toRender
    }
  }
  return null
}

export const renderIfPermissionsManagementPermissions = (permissions, requiredPermission, toRender) => {
  if(permissions !== undefined) {
    if(permissions.permissions_management === 'full') {
      return toRender
    }
    else if(permissions.permissions === 'read_only' && requiredPermission === 'read_only') {
      return toRender
    }
  }
  return null
}


// Add New Resources Below

export const renderIfPermissions = (permissions, resource, requiredPermission, toRender) => {
  if (localStorage.getItem('granular_permissions')) {
    if (toRender !== undefined) {
      if (permissions !== undefined && resource !== undefined) {
        const resourcePerms = permissions.get(resource);
        if (resourcePerms === undefined) return null;
        // Check if the required permission exists and matches
        if (resourcePerms[requiredPermission] === true) {
          return toRender;
        }
      }
    }
  }
  else{
    // render old way
    switch(resource){
      case 'billing':
        return renderIfBillingPermissions(permissions, toRender)
      case 'permissions':
        return renderIfPermissionsManagementPermissions(permissions, requiredPermission, toRender)
      default:
        return renderIfOldPermissions(permissions, requiredPermission, toRender);
    }
  }
  return null;
}

export default permissionManager();