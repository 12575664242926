import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useState } from "react";
import {Button as MaterialButton} from '@mui/material';
import { useNotify, useRefresh, useRecordContext } from 'react-admin';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    EmailDelete: {
        color: 'FF7C60'
    },
});

const BlockDeleteButton = (record) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const emailData = useRecordContext();

    const API_URL = (window.frameElement && window.frameElement.getAttribute("data-api-url")) || (process && process.env && process.env.REACT_APP_API_URL);
    
    const handleDeleteEmail = (record) => {
        const email = emailData.email;
        var requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json'}
        }
        var requestUrl = `${API_URL}/internal/backend_users/sendgrid/blocks/${email}`
        fetch(requestUrl, requestOptions)
            .then((response) => {
                if(response.ok){
                    setOpen(false);
                    refresh();
                    response.json().then((resp) => {
                        notify(`Blocks has been removed for ${email}`, {
                            type: "info",
                        });
                    });
                } else {
                    setOpen(false);
                    refresh();
                    notify(`${email} blocks removal failed. Status: ${response.status}`, {
                        type: "warning",
                    });
                }
            })
            .catch((error) => {
                notify(`Request failed. ${error}`, {type: "warning" });
            });
        };



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
    };

    var checkBlocks = emailData.sendgrid_status.map(type => (type.type))
    if(checkBlocks.includes('block')){
        return (
            <div>
                <MaterialButton onClick={handleClickOpen} className={classes.RoleDelete} ><DeleteIcon/>Delete Blocks</MaterialButton>
                <Dialog open={open}  onClose={handleClose}>
                    <DialogTitle>Delete Blocks?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to remove blocks?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton onClick={handleClose}>Cancel</MaterialButton>
                        <MaterialButton aria-label="dialog-remove-email" onClick={e => handleDeleteEmail(record)}>Delete Blocks</MaterialButton>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const BounceDeleteButton = (record) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const emailData = useRecordContext();

    const API_URL = (window.frameElement && window.frameElement.getAttribute("data-api-url")) || (process && process.env && process.env.REACT_APP_API_URL);
    
    const handleDeleteEmail = (record) => {
        const email = emailData.email;
        var requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json'}
        }
        var requestUrl = `${API_URL}/internal/backend_users/sendgrid/bounces/${email}`
        fetch(requestUrl, requestOptions)
            .then((response) => {
                if(response.ok){
                    setOpen(false);
                    refresh();
                    response.json().then((resp) => {
                        notify(`Bounces have been removed for ${email}`, {
                            type: "info",
                        });
                    });
                } else {
                    setOpen(false);
                    refresh();
                    notify(`${email} bounces removal failed. Status: ${response.status}`, {
                        type: "warning",
                    });
                }
            })
            .catch((error) => {
                notify(`Request failed. ${error}`, {type: "warning" });
            });
        };



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
    };

    var checkBounces = emailData.sendgrid_status.map(type => (type.type))
    if(checkBounces.includes('bounce')){
        return (
            <div>
                <MaterialButton onClick={handleClickOpen} className={classes.RoleDelete} ><DeleteIcon/>Delete Bounces</MaterialButton>
                <Dialog open={open}  onClose={handleClose}>
                    <DialogTitle>Delete Bounces?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to remove bounces?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton onClick={handleClose}>Cancel</MaterialButton>
                        <MaterialButton aria-label="dialog-remove-email" onClick={e => handleDeleteEmail(record)}>Delete Bounces</MaterialButton>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}


export {
    BounceDeleteButton,
    BlockDeleteButton
}
